<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { useFetch } from '@apparatix/composables';
import DataTable, { DataTableFilterEvent } from 'primevue/datatable';
import Column from 'primevue/column';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from '@primevue/core/api';
import FormSkeleton from '@apparatix/Components/FormSkeleton.vue';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';

interface PricingRateCodeAssignmentProps {
  yieldCode: { id?: string, name?: string, description?: string };
  assignedSet: Set<number>;
  unassignedSet: Set<number>;
}

interface FaceList {
  inv_number?: string
  inv_market?: string
  inv_type?: string
  name?: string
  inv_ratecode?: string
  inv_id?: number
}

const filtersAssigned = ref({
  inv_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
  inv_market: { value: null, matchMode: FilterMatchMode.IN },
  inv_type: { value: null, matchMode: FilterMatchMode.IN },
  name: { value: null, matchMode: FilterMatchMode.IN },
});

const filtersUnassigned = ref({
  inv_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
  inv_market: { value: null, matchMode: FilterMatchMode.IN },
  inv_type: { value: null, matchMode: FilterMatchMode.IN },
  name: { value: null, matchMode: FilterMatchMode.IN },
});

const props = defineProps<PricingRateCodeAssignmentProps>();
const { get } = useFetch();
const toast = useToast();
const isLoading = ref(false);

const faces = ref<FaceList[]>([{}]);

const filteredAssigned = ref([]);
const filteredUnassigned = ref([]);

const selectedAssign = ref();
const selectedUnassign = ref();

watch(selectedAssign, (newValue) => {
  if (! newValue.length) {
    return;
  }

  newValue.forEach((selectedFace) => {
    const index = faces.value.findIndex(face => face.inv_number === selectedFace.inv_number);
    if (props.unassignedSet.has(faces.value[index].inv_id)) {
      props.unassignedSet.delete(faces.value[index].inv_id);
    } else {
      props.assignedSet.add(faces.value[index].inv_id);
    }
    faces.value[index].inv_ratecode = props.yieldCode.id;
  });

  selectedAssign.value = [];
});

watch(selectedUnassign, (newValue) => {
  if (! newValue.length) {
    return;
  }

  newValue.forEach((selectedFace) => {
    const index = faces.value.findIndex(face => face.inv_number === selectedFace.inv_number);
    if (props.assignedSet.has(faces.value[index].inv_id)) {
      props.assignedSet.delete(faces.value[index].inv_id);
    } else {
      props.unassignedSet.add(faces.value[index].inv_id);
    }
    faces.value[index].inv_ratecode = 'Unassigned';
  });

  selectedUnassign.value = [];
});

const markets = computed(() => {
  const _markets = faces.value.map(face => face['inv_market']);
  return [... new Set(_markets)];
});

const types = computed(() => {
  const invTypes = faces.value.map(face => face['inv_type']);
  return [... new Set(invTypes)];
});

const yieldCodes = computed(() => {
  const _yieldCodes = faces.value.map(face => face['name']);
  const set = new Set(_yieldCodes);
  const yieldCodeMapping = [];
  set.forEach(code => {
    if (! code) {
      yieldCodeMapping.push({ name: 'Unassigned', code: null });
    } else {
      yieldCodeMapping.push({ name: code, code: code });
    }
  });

  return yieldCodeMapping;
});

// eslint-disable-next-line eqeqeq
const assignedCodes = computed(() => faces.value.filter(face => face.inv_ratecode == props.yieldCode.id));

// eslint-disable-next-line eqeqeq
const unassignedCodes = computed(() => faces.value.filter(face => face.inv_ratecode != props.yieldCode.id));

const loadData = async () => {
  isLoading.value = true;
  const data: any = await get('/pricingAssignCodes');
  if (data?.error) {
    toast.add({
      severity: 'error', summary: 'Error', detail: 'Something went wrong loading faces', life: 3000,
    });
    return;
  }
  isLoading.value = false;
  faces.value = data.faceList;
};

const onAssignClick = (face) => {selectedAssign.value = [face];};
const onAssignAllClick = () => {selectedAssign.value = filteredUnassigned.value;};
const onFilterAssigned = (e: DataTableFilterEvent) => {
  filteredAssigned.value = e.filteredValue;
};

const onUnassignClick = (face) => {selectedUnassign.value = [face];};
const onUnassignAllClick = () => {selectedUnassign.value = filteredAssigned.value;};
const onFilterUnassigned = (e: DataTableFilterEvent) => {
  filteredUnassigned.value = e.filteredValue;
};

onMounted(() => loadData());

</script>

<template>
  <FormSkeleton v-if="isLoading" />
  <DataTable
    v-else
    v-model:filters="filtersUnassigned"
    v-model:selection="selectedAssign"
    :value="unassignedCodes"
    size="small"
    data-key="inv_id"
    filter-display="row"
    striped-rows
    paginator
    :rows="5"
    :rows-per-page-options="[5, 10, 25, 100, 500, 1000, 5000]"
    table-style="min-width: 50rem;"
    removable-sort
    @filter="onFilterUnassigned"
  >
    <template #header>
      <div class="flex flex-nowrap items-center gap-2">
        <span class="text-xl text-900 font-bold">Active Faces</span>
        <pv-badge :value="filteredUnassigned.length" severity="secondary" />
      </div>
    </template>
    <template #empty>
      No Faces found.
    </template>
    <Column field="inv_number" header="Face" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="p-column-filter"
          placeholder="Faces"
          style="min-width: 8rem; max-width: 8rem"
          @input="filterCallback()"
        />
      </template>
    </Column>
    <Column field="inv_market" header="Market" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          :options="markets"
          filter
          placeholder="Markets"
          class="p-column-filter"
          style="min-width: 8rem; max-width: 8rem"
          :show-clear="true"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="inv_type"
      header="Type"
      :show-filter-menu="false"
      sortable
    >
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          :options="types"
          placeholder="Types"
          class="p-column-filter"
          style="min-width: 8rem; max-width: 8rem"
          :show-clear="true"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="name"
      header="Yield Code"
      :show-filter-menu="false"
      sortable
    >
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          :options="yieldCodes"
          option-label="name"
          option-value="code"
          placeholder="Codes"
          class="p-column-filter"
          style="min-width: 8rem; max-width: 8rem"
          :show-clear="true"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column header-style="min-width: 3rem; max-width: 3rem" :show-filter-menu="false">
      <template #filter="{}">
        <Button
          v-tooltip.left="'Add Filtered Faces'"
          size="small"
          severity="info"
          rounded
          outlined
          label="Add All"
          style="width: max-content; font-weight: 400;"
          @click="onAssignAllClick()"
        />
      </template>
      <template #body="slotProps">
        <Button
          size="small"
          severity="info"
          rounded
          outlined
          label="Add"
          style="height: 25px; font-weight: 400;"
          @click="onAssignClick(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <FormSkeleton v-if="isLoading" />
  <DataTable
    v-else
    v-model:filters="filtersAssigned"
    v-model:selection="selectedUnassign"
    :value="assignedCodes"
    size="small"
    data-key="inv_id"
    filter-display="row"
    striped-rows
    paginator
    :rows="5"
    :rows-per-page-options="[5, 10, 25, 100, 500, 1000, 5000]"
    table-style="min-width: 50rem"
    removable-sort
    @filter="onFilterAssigned"
  >
    <template #header>
      <div class="flex flex-nowrap items-center gap-2">
        <span class="text-xl text-900 font-bold">Assigned Faces</span>
        <pv-badge :value="filteredAssigned.length" severity="secondary" />
      </div>
    </template>
    <template #empty>
      No Faces found.
    </template>
    <Column field="inv_number" header="Face" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="p-column-filter"
          placeholder="Faces"
          style="min-width: 8rem; max-width: 8rem"
          @input="filterCallback()"
        />
      </template>
    </Column>
    <Column field="inv_market" header="Market" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          :options="markets"
          filter
          placeholder="Markets"
          class="p-column-filter"
          style="min-width: 8rem; max-width: 8rem"
          :show-clear="true"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="inv_type"
      header="Type"
      :show-filter-menu="false"
      sortable
    >
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          :options="types"
          placeholder="Types"
          class="p-column-filter"
          style="min-width: 8rem; max-width: 8rem"
          :show-clear="true"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="name"
      header="Yield Code"
      :show-filter-menu="false"
      sortable
    >
      <template #filter="{ filterModel, filterCallback }">
        <MultiSelect
          v-model="filterModel.value"
          :options="yieldCodes"
          option-label="name"
          option-value="code"
          placeholder="Codes"
          class="p-column-filter"
          style="min-width: 8rem; max-width: 8rem"
          :show-clear="true"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column header-style="min-width: 3rem; max-width: 3rem" :show-filter-menu="false">
      <template #filter="{}">
        <Button
          v-tooltip.left="'Remove Filtered Faces'"
          size="small"
          severity="info"
          rounded
          outlined
          label="Remove All"
          style="width: max-content; font-weight: 400;"
          @click="onUnassignAllClick()"
        />
      </template>
      <template #body="slotProps">
        <Button
          size="small"
          severity="info"
          rounded
          outlined
          label="Remove"
          style="height: 25px; font-weight: 400;"
          @click="onUnassignClick(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<style scoped>
    .custom-table-bg tr {
      background: red !important;
    }

    .p-badge {
      background: var(--primary-blue);
    }

  :deep(.p-button) {
    gap: 8px;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    background: var(--primary-blue);
    color: var(--white);
    border-radius: 8px;
    box-shadow: 0 4px 8px -4px var(--black-15);
    text-transform: capitalize;

    &.p-button-secondary {
      background: var(--white);
      border: 1px solid var(--black-16);
      color: var(--black-80);
    }
  }

  :deep(.p-checkbox .p-checkbox-box.p-highlight) {
    border: none;
    background: var(--primary-blue);
  }

 .p-inputtext:enabled:focus {
      border-color: #2FA3EE;
 }

 .p-inputtext:enabled:hover {
     border-color: #2FA3EE;
 }

 .p-multiselect:not(.p-disabled):hover {
    border-color: #2FA3EE;
  }

  :deep(.p-datatable-header) {
    background-color: #f5f5f5;
    border: none;
  }


</style>

