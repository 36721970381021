import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

type ColorMode = 'light' | 'dark';
type ColorGroup = 'gray' | 'purple' | 'blue' | 'green' | 'orange' | 'red';
type ColorGradient = '50' | '75' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000' | '1100' | '1200' | '1300';
type CssVar<TMode extends ColorMode, TGroup extends ColorGroup, TGradient extends ColorGradient> = `var(--apx-${TMode}-${TGroup}-${TGradient})`;

export type ApparatixPrimitiveColorToken<TMode extends ColorMode, TGroup extends ColorGroup> = {
  50?: TGroup extends 'gray' ? CssVar<TMode, TGroup, '50'> : never;
  75?: TGroup extends 'gray' ? CssVar<TMode, TGroup, '75'> : never;
  100: CssVar<TMode, TGroup, '100'>;
  200: CssVar<TMode, TGroup, '200'>;
  300: CssVar<TMode, TGroup, '300'>;
  400: CssVar<TMode, TGroup, '400'>;
  500: CssVar<TMode, TGroup, '500'>;
  600: CssVar<TMode, TGroup, '600'>;
  700: CssVar<TMode, TGroup, '700'>;
  800: CssVar<TMode, TGroup, '800'>;
  900: CssVar<TMode, TGroup, '900'>;
  1000?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1000'> : never;
  1100?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1100'> : never;
  1200?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1200'> : never;
  1300?: TGroup extends 'purple' | 'blue' | 'green' | 'orange' | 'red' ? CssVar<TMode, TGroup, '1300'> : never;
};

export const Apparatix = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
  },
  components: {
    tabs: {
      tabpanel: {
        padding: '0.875rem 0 1.25rem 0',
      },
    },
    datatable: {
      css: () => `
        .no-left-padding .p-datatable-tbody > tr > td:first-child,
        .no-left-padding .p-datatable-thead > tr > th:first-child {
          padding-left: 0;
        }
      `,
    },
  },
});
