<template>
  <div v-cloak class="max-w-6xl mx-auto">
    <div class="grid grid-cols-12 h-1157px">
      <div class="col-span-12 px-6">
        <proposal-header
          page-name="Target Audience Overview"
          :proposal="proposal"
        />
      </div>
      <div class="col-span-12 p-6">
        <h1 class="text-4xl font-medium max-h-20">
          {{ audienceName }}
        </h1>
      </div>
      <div v-show="summaryMeasures.length == 0" class="col-span-12 col-span-12 h-3/5">
        <app-callout>
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-red-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </template>
          <h3 class="text-lg text-red-500 font-bold">
            Error
          </h3>
          <p>Geopath Measurements have not yet loaded. Try again in a few minutes. Please contact support if this problem persists.</p>
        </app-callout>
      </div>
      <div v-if="summaryMeasures.length > 0" class="rounded-xl col-span-12 sm:col-span-8">
        <app-callout>
          <template #icon>
            <svg
              class="h-full w-full fill-current"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 34C21.5087 34 25.8327 32.2089 29.0208 29.0208C32.2089 25.8327 34 21.5087 34 17C34 12.4913 32.2089 8.1673 29.0208 4.97919C25.8327 1.79107 21.5087 0 17 0C12.4913 0 8.1673 1.79107 4.97919 4.97919C1.79107 8.1673 0 12.4913 0 17C0 21.5087 1.79107 25.8327 4.97919 29.0208C8.1673 32.2089 12.4913 34 17 34ZM4.9555 12.8074C5.74449 10.5479 7.1523 8.55547 9.0185 7.05713C9.588 7.92625 10.5697 8.5 11.6875 8.5C12.5329 8.5 13.3436 8.83582 13.9414 9.4336C14.5392 10.0314 14.875 10.8421 14.875 11.6875V12.75C14.875 13.8772 15.3228 14.9582 16.1198 15.7552C16.9168 16.5522 17.9978 17 19.125 17C20.2522 17 21.3332 16.5522 22.1302 15.7552C22.9272 14.9582 23.375 13.8772 23.375 12.75C23.3747 11.7989 23.6934 10.8752 24.2801 10.1266C24.8668 9.37807 25.6877 8.84796 26.6114 8.62113C28.6393 10.9409 29.7548 13.9188 29.75 17C29.75 17.7225 29.6905 18.4344 29.5736 19.125H27.625C26.4978 19.125 25.4168 19.5728 24.6198 20.3698C23.8228 21.1668 23.375 22.2478 23.375 23.375V28.0436C21.4376 29.1646 19.2383 29.7533 17 29.75V25.5C17 24.3728 16.5522 23.2918 15.7552 22.4948C14.9582 21.6978 13.8772 21.25 12.75 21.25C11.6228 21.25 10.5418 20.8022 9.7448 20.0052C8.94777 19.2082 8.5 18.1272 8.5 17C8.50038 15.9949 8.1445 15.0221 7.49558 14.2546C6.84665 13.487 5.94668 12.9742 4.9555 12.8074Z" />
            </svg>
          </template>
          <h3 class="text-lg text-main font-bold">
            Markets
          </h3>
          <p><span class="font-bold">{{ audience.markets[0].type }}:</span> {{ marketNames }}</p>
        </app-callout>
      </div>
      <div v-if="summaryMeasures.length > 0" class="rounded-xl col-span-12 sm:col-span-4 col-end-13 row-span-2">
        <geopath-chart
          :chart-data="pieChartData"
          :main-color="mainColor"
          type="pie3D"
        />
      </div>
      <div v-if="summaryMeasures.length > 0" class="rounded-xl col-span-12 sm:col-span-8">
        <app-callout>
          <template #icon>
            <svg
              class="h-full w-full fill-current"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 34C21.5087 34 25.8327 32.2089 29.0208 29.0208C32.2089 25.8327 34 21.5087 34 17C34 12.4913 32.2089 8.1673 29.0208 4.97919C25.8327 1.79107 21.5087 0 17 0C12.4913 0 8.1673 1.79107 4.97919 4.97919C1.79107 8.1673 0 12.4913 0 17C0 21.5087 1.79107 25.8327 4.97919 29.0208C8.1673 32.2089 12.4913 34 17 34ZM10.625 14.875C11.1886 14.875 11.7291 14.6511 12.1276 14.2526C12.5261 13.8541 12.75 13.3136 12.75 12.75C12.75 12.1864 12.5261 11.6459 12.1276 11.2474C11.7291 10.8489 11.1886 10.625 10.625 10.625C10.0614 10.625 9.52091 10.8489 9.1224 11.2474C8.72388 11.6459 8.5 12.1864 8.5 12.75C8.5 13.3136 8.72388 13.8541 9.1224 14.2526C9.52091 14.6511 10.0614 14.875 10.625 14.875ZM25.5 12.75C25.5 13.3136 25.2761 13.8541 24.8776 14.2526C24.4791 14.6511 23.9386 14.875 23.375 14.875C22.8114 14.875 22.2709 14.6511 21.8724 14.2526C21.4739 13.8541 21.25 13.3136 21.25 12.75C21.25 12.1864 21.4739 11.6459 21.8724 11.2474C22.2709 10.8489 22.8114 10.625 23.375 10.625C23.9386 10.625 24.4791 10.8489 24.8776 11.2474C25.2761 11.6459 25.5 12.1864 25.5 12.75ZM24.514 24.5119C24.7113 24.3144 24.8678 24.0801 24.9745 23.8222C25.0812 23.5643 25.1361 23.2879 25.136 23.0087C25.1359 22.7296 25.0808 22.4533 24.9739 22.1954C24.867 21.9376 24.7104 21.7034 24.5129 21.5061C24.3155 21.3088 24.0811 21.1523 23.8232 21.0456C23.5653 20.9388 23.2889 20.884 23.0098 20.8841C22.7307 20.8842 22.4543 20.9392 22.1965 21.0461C21.9387 21.1531 21.7044 21.3097 21.5071 21.5071C20.3116 22.7023 18.6904 23.3736 17 23.3736C15.3096 23.3736 13.6884 22.7023 12.4929 21.5071C12.0944 21.1084 11.5539 20.8843 10.9902 20.8841C10.4265 20.8839 9.8858 21.1076 9.48706 21.5061C9.08833 21.9045 8.86421 22.445 8.86401 23.0087C8.86381 23.5724 9.08755 24.1131 9.486 24.5119C10.4727 25.4988 11.6441 26.2817 12.9333 26.8158C14.2226 27.3499 15.6045 27.6249 17 27.6249C18.3955 27.6249 19.7774 27.3499 21.0667 26.8158C22.3559 26.2817 23.5273 25.4988 24.514 24.5119Z" />
            </svg>
          </template>
          <h3 class="text-lg text-main font-bold">
            Target Audience*
          </h3>
          <p><span class="font-bold">{{ audience.demographic.category_name }}:</span> {{ audience.demographic.name }}</p>
        </app-callout>
      </div>
      <div v-if="summaryMeasures.length > 0" class="col-span-12 sm:col-span-6 p-6 pl-7">
        <geopath-measure
          v-for="(measure, index) in summaryMeasures"
          :key="index"
          :title="measure.name"
          :value="measure.value"
          :description="measure.description"
        />
      </div>
      <div v-if="summaryMeasures.length > 0" class="rounded-xl col-span-12 sm:col-span-6 p-6">
        <geopath-chart
          :chart-data="barChartData"
          :main-color="mainColor"
          type="bar"
        />
      </div>
      <div v-if="summaryMeasures.length > 0" class="rounded-xl col-span-12">
        <app-callout>
          <template #icon>
            <span class="inline-block -mt-1 text-gray-500 text-5xl">*</span>
          </template>
          <div class="text-gray-500 text-xs leading-tight space-y-2">
            <p v-html="demographicInfo[audience.demographic.category_name]" />
            <p v-show="hasUnratedFaces">
              <span class="font-bold">Unrated Face(s):</span> This proposal includes one or more faces that have not been rated by Geopath. They are not included in the overview measurements.
            </p>
          </div>
        </app-callout>
      </div>
      <proposal-footer
        :proposal="proposal"
        :logo_url="logo_url"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    audience: Object,
    logo_url: String,
    mainColor: String,
    mapName: String,
    measurements: String,
    proposal: Object,
    hasUnratedFaces: Boolean,
  },
  data: function() {
    return {
      summaryMeasures: [],
      pieChartData: [],
      barChartData: [],
      demographicInfo: {
        'Population': '<span class="font-bold">Population:</span> Target demographics of the population that do not include group quarters (e.g. correctional facilities, military quarters, college/university student housing, nursing homes, juvenile facilities, etc.).',
        'Consumer Profiles': '<span class="font-bold">Consumer Profiles:</span> Claritas Consumer Profiles help identify groups of consumers who are as likely, more likely, or less likely than the average segment, to engage in various behaviors.',
        'PopFacts': '<span class="font-bold">PopFacts®:</span> Claritas PopFacts® Advanced provides demographic data based on the U.S. Census, American Community Survey (ACS), and other sources.',
        'Prizm Premier': '<span class="font-bold">Prizm® Premier:</span> Claritas PRIZM segmentation is a comprehensive overview of visitors, combining demographics, consumer behavior, and geographic data, grouped together into 68 segments by common characteristics and behaviors.',
      },
    };
  },
  computed: {
    audienceName: function() {
      const name = this.audience.name;
      const maxLength = 90;
      return (name.length > maxLength) ? name.substr(0, name.lastIndexOf(' ', maxLength)) + '...' : name;
    },
    marketNames: function() {
      const names = this.audience.markets.reduce((output, market) =>{
        let tmp = output;
        if (output.length > 0) {
          tmp += '; ';
        }
        return `${tmp}${market.name}`;
      }, '');
      return names;
    },
  },
  mounted: function() {
    const app = this;
    if (this.measurements.length === 0) {
      axios.get(`/proposal/geopath-measures/overall/${this.proposal.propId}/${this.audience.id}/${this.mapName}`)
        .then((response) => {
          app.summaryMeasures = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const measurements = JSON.parse(this.measurements);
      this.summaryMeasures = measurements.filter(measure => measure.includeIn.includes('overview'));
      this.pieChartData = measurements.filter(measure => measure.includeIn.includes('pieChart'));
      this.barChartData = measurements.filter(measure => measure.includeIn.includes('barChart'));
    }
  },
};
</script>

<style scoped>
</style>
