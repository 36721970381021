<!-- eslint-disable camelcase -->
<script setup lang="ts">
import { computed, ref } from 'vue';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';
import Modal from '@apparatix/Components/Modal.vue';
import PricingRule from '@apparatix/Components/PricingRule.vue';
import FormSkeleton from '@apparatix/Components/FormSkeleton.vue';

const TOAST_LIFE = 3000;

interface YieldCodeProps {
  yieldRules: string;
}

const props = defineProps<YieldCodeProps>();

const yieldRuleArray = ref(JSON.parse(props.yieldRules));

const confirm = useConfirm();
const toast = useToast();

const modalVisible = ref(false);
const action = ref<'create' | 'edit' | null>(null);
const isLoading = ref(false);
const selectedRow = ref<{ name: string, id: string, rowId: number }>({ name: '', id: undefined, rowId: undefined });

const dataPoints = ref();
const pricingRule = ref();
const dropdownLists = ref();

const modalHeaderText = computed(() => {
  if (!action.value) return null;

  return action.value === 'create' ? 'Create Yield Rule' : 'Edit Yield Rule';
});

const onCreateClick = async () => {
  modalVisible.value = false;
  isLoading.value = true;
  action.value = 'create';
  selectedRow.value = { id: null, name: '', rowId: 0 };

  const data = await axios.get('/pricingRules/create?returnJson=true');

  if (data.status !== 200) {
    const message = data.data?.message ?? 'Something went wrong';
    toast.add({
      severity: 'error', summary: 'Error', detail: message, life: TOAST_LIFE,
    });
  }
  dataPoints.value = data.data.dataPoints;
  pricingRule.value = data.data.pricingRule;
  dropdownLists.value = data.data.dropdownLists;
  modalVisible.value = true;
  isLoading.value = false;

};

const onEditClick = async (rateCode) => {
  modalVisible.value = false;
  isLoading.value = true;
  action.value = 'edit';
  selectedRow.value = { id: rateCode.id, name: rateCode.name, rowId: 0 };

  const data = await axios.get(`/pricingRules/${selectedRow.value.id}?returnJson=true`);

  if (data.status !== 200) {
    const message = data.data?.message ?? 'Something went wrong';
    toast.add({
      severity: 'error', summary: 'Error', detail: message, life: TOAST_LIFE,
    });
  }
  dataPoints.value = data.data.dataPoints;
  pricingRule.value = data.data.pricingRule;
  dropdownLists.value = data.data.dropdownLists;
  modalVisible.value = true;
  isLoading.value = false;
};

const onDeleteClick = async (rateCode) => {
  selectedRow.value = { id: rateCode.id, name: rateCode.name, rowId: 0 };

  confirm.require({
    message: 'Are you sure you want to delete this yield rule?',
    header: 'Confirm delete',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: () => deleteRule(),
  });
};

const deleteRule = async () => {
  try {
    const result = await axios.delete(`/pricingRules/${selectedRow.value.id}`);
    if (result.status === 200) {
      yieldRuleArray.value = yieldRuleArray.value.filter(code => code.id !== selectedRow.value.id);
      toast.add({
        severity: 'success', summary: 'Success', detail: 'Rule successfully deleted', life: TOAST_LIFE,
      });
    } else {
      toast.add({
        severity: 'error', summary: 'Error', detail: 'Error deleting rule', life: TOAST_LIFE,
      });
    }
  } catch (error) {
    console.log('caught error', error);
    if (error.response?.data?.message === 'THIS RULE IS CURRENTLY IN USE') {
      toast.add({
        severity: 'error',
        summary: 'Yield Rule in use',
        detail: 'This yield rule is currently assigned to a code, and cannot be deleted. If you want to delete this rule, first unassign this rule from any codes',
      });
    } else {
      toast.add({
        severity: 'error', summary: 'Error', detail: 'Error deleting rule', life: TOAST_LIFE,
      });
    }
  }
};

const onRuleCreated = (newRule) => {
  const isActive = newRule?.is_active ? 'Yes' : 'No';
  const shouldStopRules = newRule?.should_stop_rules ? 'Yes' : 'No';
  const startDate = newRule.starts_at !== 'Invalid Date' ? newRule.starts_at.toISOString().split('T')[0] : '';
  const endDate = newRule.ends_at !== 'Invalid Date' ? newRule.ends_at.toISOString().split('T')[0] : '';
  yieldRuleArray.value.push({
    id: newRule.id, name: newRule.name, description: newRule?.description, is_active: isActive, should_stop_rules: shouldStopRules, starts_at: startDate, ends_at: endDate,
  });
};

const onRuleUpdated = (rule) => {
  const changedCodeIndex = yieldRuleArray.value.findIndex(code => code.id === rule.id);
  yieldRuleArray.value[changedCodeIndex].name = rule.name;
  yieldRuleArray.value[changedCodeIndex].description = rule?.description;
  yieldRuleArray.value[changedCodeIndex].is_active = rule?.is_active ? 'Yes' : 'No';
  yieldRuleArray.value[changedCodeIndex].should_stop_rules = rule?.should_stop_rules ? 'Yes' : 'No';
  yieldRuleArray.value[changedCodeIndex].starts_at = (rule.starts_at !== 'Invalid Date' && rule.starts_at) ? rule.starts_at.toISOString().split('T')[0] : '';
  yieldRuleArray.value[changedCodeIndex].ends_at = (rule.ends_at !== 'Invalid Date' && rule.ends_at) ? rule.ends_at.toISOString().split('T')[0] : '';
};

const onClose = () => cleanup();

const cleanup = () => {
  modalVisible.value = false;
  isLoading.value = false;
  action.value = undefined;
};

</script>

<template>
  <section class="flex flex-col gap-y-8 mx-auto border-x border-slate-50 border-indigo-500" style="width: 100%; padding-left: 15px; background: #f9fafb; height: 100%;">
    <DataTable
      striped-rows
      :value="yieldRuleArray"
      class="p-datatable-sm"
    >
      <template #header>
        <div class="items-center">
          <h1 class="title">
            Yield Rules
          </h1>
          <Button
            v-tooltip.left="'Create a New Yield Rule'"
            size="small"
            rounded
            outlined
            style="background:#2B9C74; color:white"
            @click="onCreateClick()"
          >
            <i class="pi pi-plus-circle" style="font-size: 1.5rem;" /> <div style="font-weight:700;">
              Add Yield Rule
            </div>
          </Button>
        </div>
      </template>
      <template #empty>
        No Rules found.
      </template>
      <Column field="name" header="Name" />
      <Column field="description" header="Description" />
      <Column field="starts_at" header="Start Date" style="width: 120px" />
      <Column field="ends_at" header="End Date" style="width: 120px" />
      <Column field="is_active" header="Active" style="width: 100px" />
      <Column field="should_stop_rules" header="Stop Rules" style="width: 100px" />
      <Column style="width: 20px">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Edit Rule'"
            size="small"
            severity="info"
            rounded
            outlined
            class="icon-button"
            style="color:#2FA3EE"
            @click="onEditClick(slotProps.data)"
          >
            <i class="pi pi-pen-to-square" style="font-size: 1.5rem" />
          </Button>
        </template>
      </Column>
      <Column style="width: 20px">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Delete Code'"
            size="small"
            severity="danger"
            rounded
            outlined
            class="icon-button"
            style="color:#E11D48"
            @click="onDeleteClick(slotProps.data)"
          >
            <i class="pi pi-trash" style="font-size: 1.5rem" />
          </Button>
        </template>
      </Column>
    </DataTable>
  </section>
  <Modal
    :visible="modalVisible"
    :title="modalHeaderText"
    :subtitle="selectedRow.name"
    icon="circlePlus"
    disable-footer
    @on:close="onClose"
  >
    <template #body>
      <FormSkeleton v-if="isLoading" />
      <PricingRule
        v-model:dropdown-lists="dropdownLists"
        v-model:data-points="dataPoints"
        v-model:pricing-rule="pricingRule"
        @created-new-rule="onRuleCreated"
        @updated-existing-rule="onRuleUpdated"
        @cleanup="cleanup"
      />
    </template>
  </Modal>
  <ConfirmDialog />
  <Toast />
</template>

<style scoped>

  :deep(.p-checkbox .p-checkbox-box.p-highlight) {
    border: none;
    background: var(--primary-blue);
  }

    :deep(.p-button) {
    gap: 8px;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    background: var(--primary-blue);
    color: var(--white);
    border-radius: 8px;
    box-shadow: 0 4px 8px -4px var(--black-15);
    text-transform: capitalize;

    &.p-button-secondary {
      background: var(--white);
      border: 1px solid var(--black-16);
      color: var(--black-80);
    }
  }

   :deep(.p-checkbox .p-checkbox-box.p-highlight) {
    border: none;
    background: var(--primary-blue);
  }

  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .icon-button {
    background: none;
    box-shadow: none;
    border:none;
  }

</style>
