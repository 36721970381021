import { onMounted, onUnmounted, reactive } from 'vue';
import { AppStore } from '@apparatix/stores/AppStore';
import { useAppNavigation } from './useAppNavigation';

export type MessageType =
  'navigate' |
  'open-modal' |
  'navigate-by-system-id' |
  'login-page-opened' |
  'show-dashboard-notification-toast';

export interface FrameMessage<T extends MessageType, U extends object = object> {
  type: T;
  data: U;
}

export interface NavigationData {
  parentPage: string;
  childPage?: string;
  urlParameters?: Record<string, string | number>;
  appendToUrl?: string;
}

export interface OpenModalData {
  name: 'change-password'
}

export type NavigationMessage = FrameMessage<'navigate', NavigationData>;
export type NavigateBySystemIdMessage = FrameMessage<'navigate-by-system-id', [number, number | string | undefined]>;
export type OpenModalMessage = FrameMessage<'open-modal', OpenModalData>;
export type LoginPageOpenedMessage = FrameMessage<'login-page-opened', never>;
export type ShowDashboardNotificationMessage = FrameMessage<'show-dashboard-notification-toast', never>;

export const useCrossFrameMessaging = (clientMode = false) => {
  const modalStates = reactive({ shouldOpenChangePassword: false });
  const { gotoLoginPage } = useAppNavigation();

  const handleNavigationRequests = (message: NavigationMessage) => {
    if (message?.type !== 'navigate') return;
    if (!message?.data?.parentPage) return;

    const parentPage = AppStore.navigationItems.find(item => item.label.toLowerCase() === message.data.parentPage.toLowerCase());
    if (!parentPage) {
      throw new Error(`Unable to find parent navigation item ${message.data.parentPage}`);
    }

    const childPage = message.data.childPage ?
      parentPage.children.find(child => child.label.toLowerCase() === message.data.childPage.toLowerCase())
      : null;

    AppStore.setActivePage(parentPage);
    if (childPage) {
      if (message.data.urlParameters && !message.data.appendToUrl) {
        let newUrl = childPage.url;
        const { urlParameters } = message.data;

        for (const key in urlParameters) {
          if (Object.hasOwn(urlParameters, key)) {
            newUrl = `${newUrl}${key}=${urlParameters[key]}&`;
          }
        }

        childPage.url = newUrl;
      }

      if (message.data.appendToUrl && !message.data.urlParameters) {
        childPage.url = childPage.url + message.data.appendToUrl;
      }

      if (message.data.appendToUrl && message.data.urlParameters) {
        console.error('Only appendToUrl or urlParameters should be set, but got both');
      }

      AppStore.setActiveSubPage(childPage);
    }

    if (!childPage && message.data.childPage) {
      throw new Error(`Unable to find sub-page of ${message.data.parentPage}: ${message.data.childPage}`);
    }
  };

  const handleNavigationByIdRequests = (message: NavigateBySystemIdMessage) => {

    const [parent, child] = AppStore.getPagesBySystemId(message.data[0]);

    if (!parent || !child) {
      console.warn(`Unable to find page matching ${message.data}`);
      return;
    }

    if (message.data[1]) {
      if (child.url.includes('?')) {
        child.url = `${child.url}&context=${message.data[1]}`;
      } else {
        child.url = `${child.url}?context=${message.data[1]}`;
      }
    }

    AppStore.setActivePage(parent);
    AppStore.setActiveSubPage(child);
  };

  const handleShowDashboardNotificationRequests = () => AppStore.showDashboardNotificationToast();

  const handleOpenModalRequests = (message: OpenModalMessage) => {
    if (message.data.name === 'change-password') {
      modalStates.shouldOpenChangePassword = true;
    }
  };

  const dispatchMessage = (e: MessageEvent) => {
    const message = e.data as { type: MessageType };

    if (!message.type) {
      return;
    }

    switch (message.type) {
      case 'navigate':
        handleNavigationRequests(message as NavigationMessage);
        break;
      case 'open-modal':
        handleOpenModalRequests(message as OpenModalMessage);
        break;
      case 'navigate-by-system-id':
        handleNavigationByIdRequests(message as NavigateBySystemIdMessage);
        break;
      case 'login-page-opened':
      // While this looks like it might be trying to send the user to the login page when they're already there, what this actually does is notify
      // the parent iframe that the user's session has expired. The session will expire, and then the login page will show in an iframe, while
      // leaving the parent container in place. This allows the parent container to redirect to the login page to visually fully log the user out
        gotoLoginPage();
        break;
      case 'show-dashboard-notification-toast':
        handleShowDashboardNotificationRequests();
        break;
      default:
        console.warn(`No message hander for type: ${message.type}`);
    }
  };

  const sendMessage = (message: NavigationMessage | OpenModalMessage | LoginPageOpenedMessage) => {
    console.log('called sendMessage');
    if (window.self === window.top) {
      if (import.meta.env?.DEV) {
        console.warn('The page the sent a message does not appear to be running inside an iframe. `sendMessage` will only send a message when inside an iframe to prevent loops where a page is both sending and processing the same message');
      }
      return;
    }

    window.top.postMessage({
      type: message.type,
      data: message.data,
    });
    console.log('Sent message');
  };

  onMounted(() => {
    if (clientMode) return;
    window.addEventListener('message', dispatchMessage);
  });

  onUnmounted(() => {
    if (clientMode) return;
    window.removeEventListener('message', dispatchMessage);
  });

  return { modalStates, sendMessage };
};
