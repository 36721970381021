<script setup lang="ts">
import { ref } from 'vue';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';

const TOAST_LIFE = 3000;

interface ContactTypeProps {
  data: string;
}

declare global {
  interface Window {
    tabGrid: object;
  }
}

const props = defineProps<ContactTypeProps>();
const dataArray= ref(JSON.parse(props.data));

const toast = useToast();

const checkIfValid = async (type, _dataArray) => {
  if (type.selected === false) {
    _dataArray.selectedTypes.push(type.selection);
  } else {
    _dataArray.selectedTypes = _dataArray.selectedTypes.filter(item => item !== type.selection);
  }

  if (_dataArray.isSuperUser) {
    return;
  }

  const hasSalesEntity = (_dataArray.selectedTypes.filter(item => _dataArray.salesEntities.includes(item))).length !== 0;

  for (let i = 0; i < _dataArray.contactTypes.length; i++) {
    if (hasSalesEntity && _dataArray.selectedTypes.length > 0) {
      if (type.selection !== _dataArray.contactTypes[i].selection) {
        _dataArray.contactTypes[i].disabled = true;
      }
    }

    if (! hasSalesEntity && _dataArray.selectedTypes.length > 0) {
      if (_dataArray.salesEntities.includes(_dataArray.contactTypes[i].selection)) {
        _dataArray.contactTypes[i].disabled = true;
      } else {
        _dataArray.contactTypes[i].disabled = false;
      }
    }

    if (_dataArray.selectedTypes.length === 0) {
      _dataArray.contactTypes[i].disabled = false;
    }

    if (_dataArray.blockedTypes.includes(_dataArray.contactTypes[i].selection)) {
      _dataArray.contactTypes[i].disabled = true;
    }
  }
};

const updateType = async (_dataArray) => {
  const id = _dataArray.id;
  try {
    const response = await axios.post(`/core/contact/type/${id}`, _dataArray);

    if (response.status === 200) {
      toast.add({
        severity: 'success', summary: 'Success', detail: response.data.message, life: TOAST_LIFE,
      });
      parent.tabGrid['ContactsAdvertisers'].cells(id, 2).setValue(response.data.data);
      const rowId = parent.tabGrid['ContactsAdvertisers'].row.idd;
      parent.tabGrid['ContactsAdvertisers'].DataProcessor.setUpdated(rowId, true);
    }
  } catch (error) {
    toast.add({
      severity: 'error', summary: 'Error', detail: error.response.data.message, life: TOAST_LIFE,
    });
  }
};

</script>
<template>
  <div class="checkbox-column">
    <div v-for="type of dataArray.contactTypes" :key="type.id" class="checkbox-row">
      <Checkbox
        v-model="type.selected"
        class="custom-checkbox"
        :input-id="type.id"
        name="type"
        :disabled="type.disabled"
        :value="type.selection"
        :binary="true"
        @click="checkIfValid(type, dataArray)"
      />
      <label :for="type.id" class="">{{ type.selection }}</label>
    </div>
  </div>
  <div style="margin-top: 16px">
    <Button
      v-tooltip.right="'Apply type to entity'"
      size="large"
      rounded
      outlined
      class="apply-button"
      :disabled="dataArray.selectedTypes.length === 0"
      @click="updateType(dataArray)"
    >
      Apply
    </Button>
  </div>
  <Toast />
</template>


<style scoped>
.apply-button {
    background:#2B9C74;
    color:white
}
.checkbox-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 10px;
    padding-top: 10px;
}
.checkbox-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.checkbox-row label {
    margin-left: 10px;
    font-size: 12px;
    line-height: 12px;
}
.custom-checkbox {
    align-items: center;
    width: 12px;
    height: 12px;
    transform: scale(1.5);
}
</style>
