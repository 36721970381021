export const useFetch = () => {
  const getToken = () => {
    const str = `; ${document.cookie}`;
    const parts = str.split('; XSRF-TOKEN=');
    if (parts.length !== 2) return null;

    const encoded = parts.pop().split(';').shift();
    return decodeURIComponent(encoded);
  };

  // RequestPriority is defined, but eslint uses an outdated version of TS that doesn't have this type

  const get = async <T>(url: string, priority: RequestPriority = 'auto'): Promise<T> => {
    try {
      const response = await fetch(url, { priority: priority });
      const data = await response.json();
      return data as T;
    } catch (err) {
      console.error(err);
      return null;
    }
  };


  const postOrPut = async <T, U extends any | Record<string, string>>(url: string, payload: U, type: 'POST' | 'PUT', formEncoded: boolean, priority: RequestPriority): Promise<T> => {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // Even though fetch doesn't actually use XMLHttpRequest, we need to set this header because
      // Laravel uses it to determine whether to respond to some requests with JSON or a view/redirect
      'X-Requested-With': 'XMLHttpRequest',
      'X-XSRF-TOKEN': getToken(),
    };

    let body: string;
    if (formEncoded) {
      body = new URLSearchParams(payload as Record<string, string>).toString();
    } else {
      body = JSON.stringify(payload);
    }

    if (formEncoded) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    }

    const response = await fetch(url, {
      method: type,
      credentials: 'same-origin',
      headers: headers,
      body: body,
      priority: priority,
    });

    if (response?.headers.has('content-type')) {
      if (response.headers.get('content-type').includes('text/html')) {
        return await response.text() as T;
      }
    }

    const data = await response.json();
    return data as T;
  };

  const destroy = async <T>(url: string, payload?: any): Promise<T> => {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getToken(),
    };

    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: headers,
      body: payload ? JSON.stringify(payload) : '',
    });

    const data = await response.json();

    return data as T;
  };


  const put = async<T, U extends any | Record<string, string> = object>(url: string, payload: U, formEncoded = false, priority: RequestPriority = 'auto'): Promise<T> => postOrPut<T, U>(url, payload, 'PUT', formEncoded, priority);

  const post = async<T, U extends any | Record<string, string> = object>(url: string, payload: U, formEncoded = false, priority: RequestPriority = 'auto'): Promise<T> => postOrPut<T, U>(url, payload, 'POST', formEncoded, priority);

  return {
    destroy,
    get,
    post,
    put,
  };
};
