<template>
  <div :id="container" class="w-full h-full" />
</template>

<script>
export default {
  props: {
    type: String,
    chartData: Array,
    mainColor: String,
  },
  data: function() {
    return {
      container: null,
      chart:null,
    };
  },
  watch: {
    chartData: function() {
      this.drawChart();
    },
  },
  created: function() {
    this.container = 'chart' + Math.random();
  },
  mounted: function() {
    this.initChart();
    this.drawChart();
  },
  methods: {
    initChart: function() {
      console.log(this.type + 'mounted');
      let config = {};

      if (this.type === 'pie3D') {
        config = {
          css: 'geopathPieChart',
          series: [
            {
              subType: 'percentOnly',
              value: 'value',
              color: 'color',
              stroke: '#FFFFFF',
              strokeWidth: 2,
              showText: false,
            },
          ],
          legend: {
            values: {
              id: 'value',
              text: 'name',
              color: 'color',
            },
            form: 'circle',
            halign: 'center',
            valign: 'bottom',
          },
        };
      }

      if (this.type === 'bar') {
        config = {
          css: 'geopathChart',
          series: [
            {
              id: 'id',
              value: 'value',
              fill: this.mainColor,
            },
          ],
          scales: {
            left: {
              showtext: false,
            },
            bottom: {
              text: 'name',
            },
          },
        };
      }


      this.chart = new dhx.Chart(this.container, Object.assign(config, {
        type: this.type,
      }));
    },
    drawChart: function() {
      console.log('drawing chart');
      this.chart.data.parse(this.chartData);
    },
  },
};
</script>

<style>
    .geopathPieChart .legend-item {
        transform: translate(100px, 0px);
    }
    .geopathPieChart .legend-item:nth-child(2) {
        transform: translate(100px, 20px);
    }
    .geopathPieChart .legend-item:nth-child(3) {
        transform: translate(100px, 40px);
    }
    .geopathPieChart .dhx-resize-observer {
        height: 400px;
    }
    .geopathPieChart svg {
        height: 200px;
    }
    .geopathPieChart svg > g {
        transform: translate(30px, 0);
    }
    .geopathPieChart svg > g > g:nth-child(2) {
        transform: translate(106px, 60px);
    }
    .geopathPieChart svg > g > g:nth-child(3) {
        transform: translate(-100px, 180px);
    }
</style>
