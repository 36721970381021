<template>
  <div class="mt-6">
    <form v-if="isInitial || isSaving" enctype="multipart/form-data" novalidate>
      <div
        class="p-1 min-h-100 bg-gray-200 outline-2p outline-dashed outline-gray-500 outline-offset-[-5px] hover:bg-gray-300 text-gray-500 cursor-pointer"
      >
        <input
          type="file"
          name="file"
          :disabled="isSaving"
          :accept="allowedFormats"
          class="input-file"
          @change="filesChange($event.target.name, $event.target.files);"
        >
        <div class="py-8 text-lg text-center">
          <p v-if="isInitial">
            Drag a file here to upload<br> or click to browse
          </p>
          <p v-if="isSaving">
            Uploading {{ fileCount }} file...
          </p>
        </div>
      </div>
    </form>
    <div v-if="isSuccess">
      <h2>Uploaded {{ uploadedFiles.length }} file successfully.</h2>
      <p>
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click.prevent="reset()"
        >
          Upload another file
        </button>
      </p>
      <ul class="list-unstyled">
        <li v-for="item, idx in uploadedFiles" :key="idx">
          <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
        </li>
      </ul>
    </div>
    <div v-if="isFailed">
      <h2>Upload failed. {{ failureMessage }}</h2>
      <p>
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click.prevent="reset()"
        >
          Try again
        </button>
      </p>
    </div>
  </div>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  props: {
    allowedFormats: String,
    artRequestId: Number,
  },
  data: function() {
    return {
      uploadedFiles: [],
      currentStatus: null,
      failureMessage: null,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  mounted: function() {
    this.reset();
  },
  methods: {
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
    },
    save(formData) {
      this.currentStatus = STATUS_SAVING;

      const url = '/apx/artFileUpload/upload.php';

      axios.post(url, formData).then(file => {
        if (file.data.state === 'cancelled') {
          this.currentStatus = STATUS_FAILED;
          this.failureMessage = file.data.message;
        } else {
          this.uploadedFiles = [].concat(file.data);
          this.currentStatus = STATUS_SUCCESS;
          this.$emit('fileUploaded', file.data);
        }
      }).catch(err => {
        console.log('error uploading: ', err.response);
        this.currentStatus = STATUS_FAILED;
      });
    },
    filesChange(fieldName, fileList) {
      this.fileCount = fileList.length;
      const formData = new FormData();
      if (!fileList.length) return;
      formData.set('id', this.artRequestId);
      formData.set('mode', 'html5');
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });
      this.save(formData);
    },
  },
};
</script>

<style scoped>
.input-file {
  position: absolute;
  width: 100%;
  height: 200px;
  opacity: 0;
  cursor: pointer;
}
</style>
