<template>
  <div class="container m-auto grid grid-cols-12 grid-rows-2 bg-apparatix-blue text-white px-6 py-4 items-center">
    <div class="col-span-1 row-span-2">
      <h2>Digital Vendor Report</h2>
    </div>


    <div class="col-span-9 row-span-1 inline-flex items-center gap-x-4">
      <h3>Date Part</h3>

      <div v-if="isCompare" class="pr-6">
        <select
          id="dayPart"
          v-model="datePartKey"
          :disabled="true"
          name="datePart"
          class="dropdown"
        >
          <option v-for="option in ['', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year']" :key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div v-else class="pr-6">
        <select
          id="dayPart"
          v-model="datePartKey"
          :disabled="isLoading"
          name="datePart"
          class="dropdown"
        >
          <option v-for="option in ['', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year']" :key="option">
            {{ option }}
          </option>
        </select>
      </div>

      <h3 class="">
        Date Range:
      </h3>

      <div class="w-1/12 min-w-68">
        <Datepicker
          v-model="getStartDate"
          :disabled="isLoading"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="startDate"
          class="datepicker"
        />
      </div>

      <div class="w-1/12 min-w-68">
        <Datepicker
          v-model="getThruDate"
          :disabled="isLoading"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="thruDate"
          class="datepicker"
        />
      </div>

      <div class="inline-flex text-lg font-bold">
        <span>Group By: </span>
        <input
          id="vendor"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="vendor"
          value="vendor"
        >
        <label for="vendor">Digital Vendor</label>
        <input
          id="buytype"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="buytype"
          value="buytype"
        >
        <label for="buytype">Buy Type</label>
        <input
          id="office"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="office"
          value="office"
        >
        <label for="office">Office</label>
        <input
          id="market"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="market"
          value="market"
        >
        <label for="market">Local Market</label>
        <input
          id="face"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="face"
          value="face"
        >
        <label for="face">Face</label>
        <input
          id="agency"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="agency"
          value="agency"
        >
        <label for="agency">Agency</label>
        <input
          id="advertiser"
          v-model="groupedColumns"
          type="checkbox"
          class="mt-1"
          :disabled="isLoading"
          name="advertiser"
          value="advertiser"
        >
        <label for="advertiser">Advertiser</label>
      </div>
    </div>


    <div class="col-span-2 row-span-1 gap-x-4 inline-flex items-center justify-end">
      <div v-if="isLoading" class="">
        <button
          id=""
          class="inline-flex py-2 px-4 text-apparatix-blue text-xl font-bold rounded-lg justify-center border-solid border-2 border-gray-300 hover:cursor-wait w-95"
          :disabled="isLoading"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-8 h-8 pr-2"
          >
            <path fill-rule="evenodd" d="M15.22 6.268a.75.75 0 01.968-.432l5.942 2.28a.75.75 0 01.431.97l-2.28 5.941a.75.75 0 11-1.4-.537l1.63-4.251-1.086.483a11.2 11.2 0 00-5.45 5.174.75.75 0 01-1.199.19L9 12.31l-6.22 6.22a.75.75 0 11-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l3.606 3.605a12.694 12.694 0 015.68-4.973l1.086-.484-4.251-1.631a.75.75 0 01-.432-.97z" clip-rule="evenodd" />
          </svg>
          Graph
        </button>
      </div>
      <div v-else class="">
        <button
          id=""
          class="inline-flex py-2 px-4 text-white text-xl font-bold bg-apparatix-blue rounded-lg justify-center border-solid border-2 border-gray-300 hover:bg-white hover:text-apparatix-blue hover:cursor-pointer w-95"
          type="button"
          @click="toggleGraph"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-8 h-8 pr-2"
          >
            <path fill-rule="evenodd" d="M15.22 6.268a.75.75 0 01.968-.432l5.942 2.28a.75.75 0 01.431.97l-2.28 5.941a.75.75 0 11-1.4-.537l1.63-4.251-1.086.483a11.2 11.2 0 00-5.45 5.174.75.75 0 01-1.199.19L9 12.31l-6.22 6.22a.75.75 0 11-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l3.606 3.605a12.694 12.694 0 015.68-4.973l1.086-.484-4.251-1.631a.75.75 0 01-.432-.97z" clip-rule="evenodd" />
          </svg>
          Graph
        </button>
      </div>
      <div v-if="isLoading" class="">
        <button id="" class="inline-flex py-2 px-4 text-apparatix-blue text-xl font-bold rounded-lg justify-center border-solid border-2 border-gray-300 hover:cursor-wait w-95" :disabled="isLoading">
          Loading...
        </button>
      </div>
      <div v-else class="">
        <button id="" class="inline-flex py-2 px-4 text-white text-xl font-bold bg-apparatix-blue rounded-lg justify-center border-solid border-2 border-gray-300 hover:bg-white hover:text-apparatix-blue hover:cursor-pointer w-95" @click="groupBy">
          Run Report
        </button>
      </div>
    </div>




    <div class="col-span-8 row-span-1 inline-flex items-center gap-x-4">
      <Switch v-model="isCompare" :disabled="isLoading" :class="[isCompare ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-9 w-16 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ml-107']">
        <span class="sr-only">Use setting</span>
        <span aria-hidden="true" :class="[isCompare ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
      </Switch>

      <h3>Compare To: </h3>

      <div v-if="isCompare" class="w-1/12 min-w-68">
        <Datepicker
          v-model="getCompareStartDate"
          :disabled="isLoading"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="startDate"
          class="datepicker"
        />
      </div>
      <div v-else class="w-1/12 min-w-68">
        <Datepicker
          v-model="getCompareStartDate"
          :disabled="true"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="startDate"
          class="datepicker"
        />
      </div>

      <div v-if="isCompare" class="w-1/12 min-w-68">
        <Datepicker
          v-model="getCompareThruDate"
          :disabled="isLoading"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="thruDate"
          class="datepicker"
        />
      </div>
      <div v-else class="w-1/12 min-w-68">
        <Datepicker
          v-model="getCompareThruDate"
          :disabled="true"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="thruDate"
          class="datepicker"
        />
      </div>

      <div v-if="isCompare" class="">
        <select
          id="dayPart"
          v-model="aggregateSource"
          :disabled="isLoading"
          name="datePart"
          class="dropdown"
        >
          <option v-for="(value, index) in aggregateList" :key="index" :value="index">
            {{ value }}
          </option>
        </select>
      </div>
      <div v-else class="">
        <select
          id="dayPart"
          v-model="aggregateSource"
          :disabled="true"
          name="datePart"
          class="dropdown"
        >
          <option v-for="(value, index) in aggregateList" :key="index" :value="index">
            {{ value }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-span-3 row-span-1 gap-x-4 inline-flex items-center justify-end">
      <div v-if="isLoading" class="">
        <button
          id=""
          class="inline-flex py-2 px-4 text-apparatix-blue text-xl font-bold rounded-lg justify-center border-solid border-2 border-gray-300 hover:cursor-wait w-95"
          :disabled="isLoading"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-8 h-8 pr-2"
          >
            <path fill-rule="evenodd" d="M.99 5.24A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25l.01 9.5A2.25 2.25 0 0116.76 17H3.26A2.267 2.267 0 011 14.74l-.01-9.5zm8.26 9.52v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 00.627-.74zm1.5 0a.75.75 0 00.627.74h5.373a.75.75 0 00.75-.75v-.615a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625zm6.75-3.63v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75zM17.5 7.5v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75z" clip-rule="evenodd" />
          </svg>
          Export
        </button>
      </div>
      <div v-else class="">
        <button
          id=""
          class="inline-flex py-2 px-4 text-white text-xl font-bold bg-apparatix-blue rounded-lg justify-center border-solid border-2 border-gray-300 hover:bg-white hover:text-apparatix-blue hover:cursor-pointer w-95"
          type="button"
          @click="exportData"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-8 h-8 pr-2"
          >
            <path fill-rule="evenodd" d="M.99 5.24A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25l.01 9.5A2.25 2.25 0 0116.76 17H3.26A2.267 2.267 0 011 14.74l-.01-9.5zm8.26 9.52v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 00.627-.74zm1.5 0a.75.75 0 00.627.74h5.373a.75.75 0 00.75-.75v-.615a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625zm6.75-3.63v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75zM17.5 7.5v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75z" clip-rule="evenodd" />
          </svg>
          Export
        </button>
      </div>

      <div v-if="isLoading" class="">
        <button
          id=""
          class="inline-flex py-2 px-4 text-apparatix-blue text-xl font-bold rounded-lg justify-center border-solid border-2 border-gray-300 hover:cursor-wait w-95"
          :disabled="isLoading"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-8 h-8 pr-2"
          >
            <path fill-rule="evenodd" d="M5 2.75C5 1.784 5.784 1 6.75 1h6.5c.966 0 1.75.784 1.75 1.75v3.552c.377.046.752.097 1.126.153A2.212 2.212 0 0118 8.653v4.097A2.25 2.25 0 0115.75 15h-.241l.305 1.984A1.75 1.75 0 0114.084 19H5.915a1.75 1.75 0 01-1.73-2.016L4.492 15H4.25A2.25 2.25 0 012 12.75V8.653c0-1.082.775-2.034 1.874-2.198.374-.056.75-.107 1.127-.153L5 6.25v-3.5zm8.5 3.397a41.533 41.533 0 00-7 0V2.75a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25v3.397zM6.608 12.5a.25.25 0 00-.247.212l-.693 4.5a.25.25 0 00.247.288h8.17a.25.25 0 00.246-.288l-.692-4.5a.25.25 0 00-.247-.212H6.608z" clip-rule="evenodd" />
          </svg>
          Print
        </button>
      </div>
      <div v-else class="">
        <button
          id=""
          class="inline-flex py-2 px-4 text-white text-xl font-bold bg-apparatix-blue rounded-lg justify-center border-solid border-2 border-gray-300 hover:bg-white hover:text-apparatix-blue hover:cursor-pointer w-95"
          type="button"
          @click="printData"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-8 h-8 pr-2"
          >
            <path fill-rule="evenodd" d="M5 2.75C5 1.784 5.784 1 6.75 1h6.5c.966 0 1.75.784 1.75 1.75v3.552c.377.046.752.097 1.126.153A2.212 2.212 0 0118 8.653v4.097A2.25 2.25 0 0115.75 15h-.241l.305 1.984A1.75 1.75 0 0114.084 19H5.915a1.75 1.75 0 01-1.73-2.016L4.492 15H4.25A2.25 2.25 0 012 12.75V8.653c0-1.082.775-2.034 1.874-2.198.374-.056.75-.107 1.127-.153L5 6.25v-3.5zm8.5 3.397a41.533 41.533 0 00-7 0V2.75a.25.25 0 01.25-.25h6.5a.25.25 0 01.25.25v3.397zM6.608 12.5a.25.25 0 00-.247.212l-.693 4.5a.25.25 0 00.247.288h8.17a.25.25 0 00.246-.288l-.692-4.5a.25.25 0 00-.247-.212H6.608z" clip-rule="evenodd" />
          </svg>
          Print
        </button>
      </div>
    </div>
  </div>

  <div id="gridbox" style="width: 100%; height:100%; box-sizing:content-box;" />

  <div v-if="showGraph" class="container m-auto grid grid-cols-12 grid-rows-2 bg-apparatix-blue text-white px-6 py-4 items-center">
    <div class="col-span-2 row-span-2">
      <h2>Digital Vendor Graph</h2>
    </div>
    <div class="col-span-7 row-span-1 inline-flex items-center">
      <h3 class="">
        Date Range:
      </h3>

      <div class="w-1/12 min-w-68 pr-3">
        <Datepicker
          v-model="getGraphStartDate"
          :disabled="isLoading"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="startDate"
          class="datepicker"
        />
      </div>

      <div class="w-1/12 min-w-68 pr-6">
        <Datepicker
          v-model="getGraphThruDate"
          :disabled="isLoading"
          input-format="MM/dd/yyyy"
          placeholder="Select Date"
          name="thruDate"
          class="datepicker"
        />
      </div>
    </div>

    <div class="col-span-3 row-span-2 gap-x-4 inline-flex items-center justify-end">
      <div v-if="isLoading" class="">
        <button id="" class="inline-flex py-2 px-4 text-apparatix-blue text-xl font-bold rounded-lg justify-center border-solid border-2 border-gray-300 hover:cursor-wait" :disabled="isLoading">
          Loading...
        </button>
      </div>
      <div v-else class="">
        <button id="" class="inline-flex py-2 px-4 text-white text-xl font-bold bg-apparatix-blue rounded-lg justify-center border-solid border-2 border-gray-300 hover:bg-white hover:text-apparatix-blue hover:cursor-pointer" @click="updateGraph">
          Update Graph
        </button>
      </div>
    </div>

    <div class="col-span-7 row-span-1 inline-flex items-center gap-x-12">
      <h3>Graph:</h3>
      <div class="">
        <select
          id="dayPart"
          v-model="graphSource"
          :disabled="isLoading"
          name="datePart"
          class="dropdown"
        >
          <option v-for="(value) in graphList" :key="value" :value="value">
            {{ value }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-show="showGraph" id="chartContainer" style="width: 100%;height:35%;" />
</template>

<script>

export default {
  components: {},
  props: [
    'startDate',
    'thruDate',
    'compareStartDate',
    'compareThruDate',
    'graphData',
    'graphStartDate',
    'graphThruDate',
    'defaultCompare',
    'defaultGroupBy',
    'willShowVendorNetRevenue',
  ],
  data: function() {
    return {
      groupedColumns: this.defaultGroupBy,
      getStartDate: this.$dateFns.parse(this.startDate, 'yyyy-MM-dd', new Date()),
      getThruDate: this.$dateFns.parse(this.thruDate, 'yyyy-MM-dd', new Date()),
      getCompareStartDate: this.$dateFns.parse(this.compareStartDate, 'yyyy-MM-dd', new Date()),
      getCompareThruDate: this.$dateFns.parse(this.compareThruDate, 'yyyy-MM-dd', new Date()),
      datePartKey: '',
      isCompare: this.defaultCompare,
      aggregateList: this.getAggregateList(),
      aggregateSource: 'gross_revenue',
      isLoading: false,
      dhxGrid: true,// init grid built in blade - differentiates on print/export
      showGraph: true,
      getGraphStartDate: this.$dateFns.parse(this.graphStartDate, 'yyyy-MM-dd', new Date()),
      getGraphThruDate: this.$dateFns.parse(this.graphThruDate, 'yyyy-MM-dd', new Date()),
      graphSource: 'Gross Revenue',
      chart: null,
      dynamicGraphData: JSON.parse(this.graphData),
      graphList: this.getGraphList(),
    };
  },
  computed: {},
  watch: {
    groupedColumns: {
      handler: function(newValue) {
        if (this.groupedColumns.newValue !== undefined) {
          this.groupedColumns.newValue = newValue;
        } else {
          delete this.groupedColumns.newValue;
        }

      },
      deep: true,
    },
    datePartKey: {
      handler: function(newValue) {
        this.datePartKey = newValue;
        if (newValue !== '' && this.isCompare === true) {
          this.isCompare = false;
          toast('notice', 'Remove Compare To - Cannot Use with Date Part.');
        }
      },
      deep: true,
    },
    getCompareStartDate: {
      handler: function(newValue) {
        this.getCompareStartDate = newValue;
      },
      deep: true,
    },
    getCompareThruDate: {
      handler: function(newValue) {
        this.getCompareThruDate = newValue;
      },
      deep: true,
    },
    getStartDate: {
      handler: function(newValue) {
        this.getStartDate = newValue;
      },
      deep: true,
    },
    getThruDate: {
      handler: function(newValue) {
        this.getThruDate = newValue;
      },
      deep: true,
    },
    dhxGrid: {
      handler: function() {
        this.setLoading(false);
      },
      deep: true,
    },
    isCompare: {
      handler: function(newValue) {
        if (newValue === true && this.datePartKey !== '') {
          // this.datePartKey = '';
          // toast('notice', 'Removing Date Part - Cannot Use with Compare To.');
        }
        if (newValue === true && this.groupedColumns.length === 0) {
          this.isCompare = false;
          toast('notice', 'Must Select Group By with Compare To.');
        }
      },
      deep: true,
    },
  },
  mounted: function() {
    this.initChart();
    this.drawChart();
    window.dispatchEvent(new Event('digital-vendor-report-input-ready'));
  },
  methods: {
    destroyGrid: function() {
      if (this.dhxGrid && typeof this.dhxGrid.destructor === 'function') {
        this.dhxGrid.destructor();
      }
    },
    destroyGraph: function() {
      if (this.chart && typeof this.chart.destructor === 'function') {
        this.chart.destructor();
      }
    },
    displayGraph: function(display) {
      this.display = ! display;
    },
    displayCompareDropdown: function(isCompare) {
      if (this.groupedColumns.length === 0) {
        toast('notice', 'You must select one or more Group By fields.');
      } else {
        this.isCompare = ! isCompare;
      }
    },
    exportData: function() {
      exportReport(this.dhxGrid);
    },
    getAggregateList: function() {
      const aggregateList = {
        impressions: 'Impressions',
        ad_plays: 'Ad Plays',
        average_cpm: 'Average CPM',
        gross_revenue: 'Gross Revenue',
        vendor_net_revenue: 'Vendor Net Revenue',
        net_revenue: 'Net Revenue',
      };

      if (! this.willShowVendorNetRevenue) {
        delete aggregateList.vendor_net_revenue;
      }

      return aggregateList;
    },
    getGraphList: function() {
      return Object.values(this.getAggregateList());
    },
    groupBy: function() {
      this.setLoading(true);
      const getCompareColumns = this.isCompare;
      const data = {
        groupedColumns: this.groupedColumns,
        startDate: this.getStartDate,
        thruDate: this.getThruDate,
        datePartKey: this.datePartKey,
        isCompare: this.isCompare,
        compareStartDate: this.getCompareStartDate,
        compareThruDate: this.getCompareThruDate,
        aggregateSource: this.aggregateSource,
      };

      if (this.groupedColumns.length === 0 && this.isCompare !== false) {
        toast('notice', 'Can\'t run report: At least one group by must be selected while compare to is active');
        this.setLoading(false);
        return;
      }

      axios.post('/digital-vendor/report-group-by', data)
        .catch(error => {
          this.setLoading(false);
          toast('warning', error.response.data.message);
        })
        .then(response => {
          if (response.status === 200) {
            this.destroyGrid();
          }
          // @todo: cpm average aggregation broken
          this.dhxGrid = buildGrid(response.data.tableData, '', {
            apxBeforeInit: function() {
              const textAlignList = ['text-align:right;','text-align:right;','text-align:right;','text-align:right;','text-align:right;','text-align:right;','text-align:right;'];
              const showVendorNetRevenue = response.data.showVendorNetRevenue;

              if (showVendorNetRevenue) {
                textAlignList.push('text-align:right;');
              }

              if (getCompareColumns) {
                let startString = '';
                let footerString;
                const footerArray = textAlignList;
                for (let i = 0; i < data.groupedColumns.length - 1; i++) {
                  startString += ',';
                  footerArray.push('text-align:right;');
                }

                if (data.aggregateSource === 'impressions' || data.aggregateSource === 'ad_plays') {
                  footerString = 'Totals,#stat_total,#stat_total,#stat_total,,${#stat_total},${#stat_total}';
                } else if (data.aggregateSource === 'gross_revenue') {
                  footerString = 'Totals,#stat_total,#stat_total,,${#stat_total},${#stat_total},${#stat_total}';
                }
                else if (data.aggregateSource === 'average_cpm') {
                  footerString = 'Totals,#stat_total,#stat_total,,,${#stat_total},${#stat_total}';
                } else {
                  footerString = 'Totals,{#stat_total},{#stat_total},,${#stat_total},${#stat_total},${#stat_total}';
                }

                if (showVendorNetRevenue) {
                  footerString = footerString.concat(',${#stat_total}');
                }
                this.attachFooter(startString + footerString, footerArray);
              } else if (data.datePartKey === '' && data.groupedColumns.length === 0) {
                const footerArray = [];
                for (let i = 0; i < 16; i++) {
                  footerArray.push('text-align:right;');
                }
                let footerString = ',,,,,,,,,,Totals,#stat_total,#stat_total,${#stat_average},${#stat_total},${#stat_total}';
                if (showVendorNetRevenue) {
                  footerString = footerString.concat(',${#stat_total}');
                }
                this.attachFooter(footerString, footerArray);
              } else {
                let startString = '';
                let footerArray = textAlignList;
                for (let i = 0; i < data.groupedColumns.length - 1; i++) {
                  startString += ',';
                  footerArray.push('text-align:right;');
                }
                if (data.datePartKey !== '' && data.groupedColumns.length === 0) {
                  startString = '';
                  footerArray = [];
                }
                if (data.datePartKey !== '' && data.groupedColumns.length !== 0) {
                  startString += ',';
                  footerArray.push('text-align:right;');
                }
                let footerString = 'Totals,#stat_total,#stat_total,${#stat_average},${#stat_total},${#stat_total}';
                if (showVendorNetRevenue) {
                  footerString = footerString.concat(',${#stat_total}');
                }
                this.attachFooter(startString + footerString, footerArray);
              }
            },
          });
        });
    },
    printData: function() {
      printReport(this.dhxGrid);
    },
    setLoading: function(isLoading) {
      this.isLoading = isLoading;
    },
    toggleGraph: function() {
      this.showGraph = ! this.showGraph;

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      sleep(100).then(() => { this.dhxGrid.setSizes(); });
    },
    initChart: function() {
      let config = {};

      config = {
        type: 'spline',
        css: 'dhx_widget--bordered',
        scales: {
          left: {},
          bottom: { text: 'hour_of_day' },
        },
        series: [
          {
            id: this.graphSource,
            value: this.graphSource,
            color: '#5865f4',
            pointType: 'circle',
            pointColor: '#5865f4',
          },
        ],
        legend: {
          series: [this.graphSource],
          halign: 'right',
          valign: 'top',
        },
      };

      this.chart = new dhx.Chart('chartContainer', config);
    },
    drawChart: function() {
      const data = this.dynamicGraphData;
      this.chart.data.parse(data, 'json');
    },
    updateGraph: function() {
      this.setLoading(true);

      if (this.getGraphStartDate.getTime() === this.getGraphThruDate.getTime()) {
        toast('notice', 'Can\'t Create Graph: A range of at least two days or more must be selected');
        this.setLoading(false);
        return;
      }

      const data = {
        graphStartDate: this.getGraphStartDate,
        graphThruDate: this.getGraphThruDate,
        graphSource: this.graphSource,
      };

      axios.post('/digital-vendor/report-graph', data)
        .catch(error => {
          this.setLoading(false);
          toast('warning', error.response.data.message);
        })
        .then(response => {
          if (response.status === 200) {
            this.destroyGraph();
          }
          this.dynamicGraphData = response.data.graphData;

          this.initChart();
          this.drawChart();
          this.setLoading(false);
        });
    },
  },
};
</script>

<style lang="css">
  /* probably a better way to handle this import path */
  @import '../../../public/res/dhx/7.2/codebase/suite.css';
</style>
