import { useToast } from 'primevue/usetoast';
import { ToastMessageOptions } from 'primevue/toast';

const DEFAULT_LIFETIME = 3000;

export const useToastNotifications = () => {
  const toast = useToast();

  const showSuccess = (message: string, title: string = 'Success', lifetime: number = DEFAULT_LIFETIME, keepOpen: boolean = false) => {
    const options: ToastMessageOptions = {
      severity: 'success',
      summary: title,
      detail: message,
    };

    if (!keepOpen) {
      options.life = lifetime;
    }

    toast.add(options);
  };

  const showError = (message: string, title: string = 'Error', lifetime: number = DEFAULT_LIFETIME, keepOpen: boolean = false) => {
    const options: ToastMessageOptions = {
      severity: 'error',
      summary: title,
      detail: message,
    };

    if (!keepOpen) {
      options.life = lifetime;
    }

    toast.add(options);
  };

  const showWarning = (message: string, title: string = 'Warning', lifetime: number = DEFAULT_LIFETIME, keepOpen: boolean = false) => {
    const options: ToastMessageOptions = {
      severity: 'warn',
      summary: title,
      detail: message,
    };

    if (!keepOpen) {
      options.life = lifetime;
    }

    toast.add(options);
  };

  const showInfo = (message: string, title: string = 'Info', lifetime: number = DEFAULT_LIFETIME, keepOpen: boolean = false) => {
    const options: ToastMessageOptions = {
      severity: 'info',
      summary: title,
      detail: message,
    };

    if (!keepOpen) {
      options.life = lifetime;
    }

    toast.add(options);
  };

  return {
    DEFAULT_LIFETIME,
    showSuccess,
    showInfo,
    showWarning,
    showError,
  };
};
