<template>
  <div v-cloak>
    <div class="grid grid-cols-12">
      <div class="col-span-12 p-2">
        <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
          <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900">Yield Rule Name</label>
          <input
            id="name"
            v-model="rule.name"
            type="text"
            name="name"
            class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm input-background"
          >
        </div>
      </div>
      <div class="col-span-4 p-2">
        <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
          <label for="startAt" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900">Effective Start Date</label>
          <Datepicker
            v-model="rule.starts_at"
            name="startAt"
            clearable="true"
            input-format="yyyy-MM-dd"
            placeholder="YYYY-MM-DD"
            class="inline-block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm input-background"
          >
            <template
              #clear="{ onClear }"
            >
              <div class="absolute inline-block left-2">
                <button @click="onClear">
                  x
                </button>
              </div>
            </template>
          </Datepicker>
        </div>
      </div>
      <div class="col-span-4 p-2">
        <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
          <label for="endAt" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900">Effective End Date</label>
          <Datepicker
            v-model="rule.ends_at"
            name="endAt"
            clearable="true"
            input-format="yyyy-MM-dd"
            placeholder="YYYY-MM-DD"
            class="inline-block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm input-background"
          >
            <template
              #clear="{ onClear }"
            >
              <div class="absolute inline-block left-2">
                <button @click="onClear">
                  x
                </button>
              </div>
            </template>
          </Datepicker>
        </div>
      </div>

      <div class="mt-4 col-span-12 p-2 border-2 border-dashed">
        <div class="grid grid-cols-12">
          <div class="col-span-4">
            <h1>Rate Calculations</h1>
          </div>
          <div class="col-span-8 flex place-content-end">
            <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="newCalculation(rule)">
              Add Calculation
              <!-- <EnvelopeIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" /> -->
            </button>
          </div>
          <div class="col-span-12">
            <span class="text-xs">Drag to reorder</span>
          </div>
          <div class="col-span-12 bg-slate-200 p-4">
            <Draggable
              v-model="rule.pricing_calculations"
              :disabled="!enabled"
              item-key="id"
              class="list-group"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="dragging = false"
              @change="log"
            >
              <template #item="{ element }">
                <div :class="{ 'not-draggable': !enabled }" class="grid grid-cols-12 p-2 text-sm border border-solid border-black list-group-item cursor-grab active:cursor-grabbing">
                  <div class="col-span-1 relative flex items-start items-center text-xs">
                    <div class="flex h-5 items-center">
                      <input
                        :id="`isActiveCalculation${element.id}`"
                        v-model="element.is_active"
                        aria-describedby="isActive-description"
                        name="isActive"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        @change="updateCalculation(element)"
                      >
                    </div>
                    <div class="ml-2">
                      <label :for="`isActiveCalculation${element.id}`" class="font-medium text-gray-700">Active</label>
                    </div>
                  </div>
                  <div class="col-span-10 relative flex items-center">
                    <span>{{ element.as_string }}</span>
                  </div>
                  <div class="col-span-1">
                    <div class="icon apx-icon-pencil" @click="editCalculation(element)">
                      <label class="cursor-pointer">Edit</label>
                    </div>
                    <div class="icon apx-icon-trash" @click="deleteCalculation(element)">
                      <label class="cursor-pointer">Remove</label>
                    </div>
                  </div>
                </div>
              </template>
            </Draggable>
          </div>
        </div>
      </div>

      <div v-if="isEditingCalculation" class="mt-4 col-span-12 border border-solid border-2 p-2">
        <PricingCalculationForm
          :data-points="dataPoints"
          :pricing-rule="rule"
          :init-calculation="currentCalculation"
          :dropdown-lists="dropdownLists"
          @calculation-cancel="isEditingCalculation = false"
          @calculation-save="addCalculation"
        />
      </div>

      <div class="mt-4 col-span-12 p-2">
        <label for="description" class="block text-sm font-medium text-gray-700">Describe the intended purpose of the yield rule:</label>
        <div class="mt-1">
          <textarea
            id="description"
            v-model="rule.description"
            rows="4"
            name="description"
            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-4 col-span-12 flex place-content-end space-x-4 items-center p-2">
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="stops_rules"
              v-model="rule.should_stop_rules"
              aria-describedby="stopsRules-description"
              name="stops_rules"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
            >
          </div>
          <div class="ml-3 text-sm">
            <span id="stopsRules-description" class="text-gray-500"> If any action in this rule is performed, do not process further rules. </span>
          </div>
        </div>
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="isActive"
              v-model="rule.is_active"
              aria-describedby="isActive-description"
              name="isActive"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
            >
          </div>
          <div class="ml-3 text-sm">
            <label for="isActive" class="font-medium text-gray-700">Active</label>
          </div>
        </div>
        <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="$emit('cleanup')">
          Cancel
        </button>
        <button type="button" class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="savePricingRule">
          Save
        </button>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import { useToast } from 'primevue/usetoast';
import PricingCalculationForm from '@apparatix/Components/PricingCalculationForm.vue';

export default {
  components: { PricingCalculationForm },
  props: [
    'dataPoints',
    'pricingRule',
    'typeable',
    'clearable',
    'dropdownLists',
    'checkMove',
  ],
  emits: ['createdNewRule', 'updatedExistingRule', 'cleanup'],
  data: function() {
    const rule = { ...this.pricingRule };
    if (this.pricingRule.starts_at !== null) {
      rule.starts_at = new Date(this.pricingRule.starts_at);
    }
    if (this.pricingRule.ends_at !== null) {
      rule.ends_at = new Date(this.pricingRule.ends_at);
    }

    return {
      toast: useToast(),
      isEditingCalculation: false,
      rule: rule,
      conditions: [],
      actions: [],
      enabled: true,
      dragging: false,
      currentCalculation: {
      },
    };
  },
  methods: {
    newCalculation: function(rule) {
      if (rule.id === undefined) {
        this.toast.add({
          severity: 'error', summary: 'Error', detail: 'Please create/save rule before adding calculations.', life: 3000,
        });
      } else {
        this.currentCalculation = {};
        this.isEditingCalculation = true;
      }
    },
    calculationActionString: function(calculation) {
      if (calculation.pricing_actions) {
        return calculation.pricing_actions;
      }
      return `Calc id: ${calculation.id} - ${calculation.pricing_conditions}: ${calculation.pricing_actions}`;
    },
    calculationConditionString: function(calculation) {
      return calculation.pricing_conditions;
    },
    editCalculation: function(calculation) {
      this.currentCalculation = calculation;
      this.isEditingCalculation = true;
    },
    deleteCalculation: function(calculation) {
      const that = this;

      const confirmPrompt = confirm('Are you sure you want to delete this pricing calculation?\n');
      if (confirmPrompt) {

        axios.delete(`/pricingCalculations/${calculation.id}`)
          .catch(error => {
            this.toast.add({
              severity: 'error', summary: 'Error', detail: error.message, life: 3000,
            });
          })
          .then(response => {
            this.toast.add({
              severity: 'success', summary: 'Success', detail: response.data.message, life: 3000,
            });
            // Disabling rule here in case ids are "equal" but have mismatched types, eg 10 == '10' is true where 10 === '10' is false
            // eslint-disable-next-line eqeqeq
            const index = that.rule.pricing_calculations.findIndex((calc) => calc.id == calculation.id);
            that.rule.pricing_calculations.splice(index, 1);
            // eslint-disable-next-line eqeqeq
            if (that.currentCalculation.id == calculation.id) {
              that.iseditingcalculation = false;
            }
          });
      }
    },
    updateCalculation: function(calculation) {
      axios.put(`/pricingCalculations/${calculation.id}`, calculation)
        .catch(error => {
          const errorMessage = error.response.data.message;
          this.toast.add({
            severity: 'error', summary: 'Error', detail: errorMessage, life: 3000,
          });
        })
        .then(() => {
          this.toast.add({
            severity: 'success', summary: 'Success', detail: 'Rule Updated!', life: 3000,
          });
        });
    },
    log: function(evt) {
      window.console.log(evt);
    },
    addCalculation: function(calculation) {
      this.isEditingCalculation = false;
      if (calculation) {
        // Disabling rule here in case ids are "equal" but have mismatched types, eg 10 == '10' is true where 10 === '10' is false
        // eslint-disable-next-line eqeqeq
        const index = this.rule.pricing_calculations.findIndex((calc) => calc.id == calculation.id);
        if (index === -1) {
          this.rule.pricing_calculations.push(calculation);
        } else {
          this.rule.pricing_calculations[index] = calculation;
        }
      }
    },
    savePricingRule: function() {
      const that = this;
      if (this.rule.pricing_calculations !== undefined) {
        for (let index = 0; index < this.rule.pricing_calculations.length; ++index) {
          this.rule.pricing_calculations[index]['sort'] = index;
        }
      }
      const request = {
        method: 'post',
        url: '/pricingRules',
        data: this.rule,
      };
      if (this.rule.id) {
        request.method = 'put';
        request.url = `/pricingRules/${this.rule.id}`;
      }
      axios(request)
        .then(response => {
          const responseMessage = response.data.message;
          this.toast.add({
            severity: 'success', summary: 'Success', detail: responseMessage, life: 3000,
          });
          that.rule.id = response.data.rule.id;
          if (this.rule.pricing_calculations === undefined) {
            this.rule.pricing_calculations = [];
          }
          if (request.method === 'put') {
            this.$emit('updatedExistingRule', this.rule);
          } else {
            this.$emit('createdNewRule', this.rule);
          }
        })
        .catch(error => {
          const errorMessage = error.response.data.message;
          this.toast.add({
            severity: 'error', summary: 'Error', detail: errorMessage, life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.bg-indigo-600{
  background-color: var(--primary-blue);
}

.text-indigo-600 {
  color: var(--primary-blue);
}

.focus-within\:border-indigo-600:focus-within {
  border-color: var(--primary-blue);
}

.focus\:border-indigo-500:focus {
  border-color: var(--primary-blue);

}
:deep(.input-background) {
  background-color: #f5f5f5;
}
</style>
