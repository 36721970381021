<template>
  <div v-cloak>
    <div class="grid grid-cols-12">
      <div class="flex flex-col col-span-6 mt-6 pt-2 px-4 gap-2 text-input-group">
        <label for="yieldCodeName">Name</label>
        <input-text id="yieldCodeName" v-model="scopedRateCodeName" @update:model-value="onNameChange" />
      </div>
      <div class="flex flex-col col-span-12 gap-2 mt-6 pt-2 px-4 text-input-group">
        <label for="yieldCodeDescription">Description</label>
        <pv-textarea
          id="yieldCodeDescription"
          v-model="scopedRateCodeDescription"
          auto-resize
          @update:model-value="onDescriptionChange"
        />
      </div>
      <div class="mt-6 col-span-12 px-4 pt-2">
        <h1 style="font-size: 16px; color: var(--black-80); font-weight: 600">
          Select Yield Rules
        </h1>
        <p style="font-size: 14px; color: var(--black-48)">
          Drag, drop, and reorder Yield Rules to create a series of calculations.
        </p>
      </div>

      <div class="col-span-12 px-4 mt-6">
        <pv-divider />
      </div>

      <div class="col-span-12 px-4 mt-6">
        <div class="drag-container">
          <div class="rule-column">
            <div class="rule-header">
              <p>Available Rules</p>
              <pv-badge :value="availableRulesCount" severity="secondary" />
              <p
                class="all-rules-action"
                :style="{ 'color': availableRulesCount > 0 ? 'var(--primary-blue)' : 'var(--black-40)' }"
                @click="addAllRules"
              >
                Add All
              </p>
            </div>
            <Draggable
              v-model="scopedPricingRules"
              class="draggable-container"
              group="rules"
              item-key="id"
              @change="log"
            >
              <template #item="{ element }">
                <yield-code-rule
                  :name="element.name"
                  :description="element.description"
                  :item-key="element.id.toString() "
                />
              </template>
            </Draggable>
          </div>
          <div class="swap-icon">
            <swap-icon />
          </div>
          <div class="rule-column">
            <div class="rule-header">
              <p>Selected Rules</p>
              <pv-badge :value="selectedRulesCount" severity="secondary" />
              <p
                class="all-rules-action"
                :style="{'color': selectedRulesCount > 0 ? 'var(--primary-blue)' : 'var(--black-40)'}"
                @click="removeAllRules"
              >
                Remove All
              </p>
            </div>
            <Draggable
              v-model="scopedSelectedRules"
              class="draggable-container"
              item-key="id"
              group="rules"
              @change="log"
            >
              <template #item="{ element }">
                <yield-code-rule
                  :name="element.name"
                  :description="element.description"
                  :item-key="element.id.toString() "
                />
              </template>
            </Draggable>
          </div>
        </div>
      </div>

      <div class="mt-6 col-span-12 px-4 pt-2">
        <h1 style="font-size: 16px; color: var(--black-80); font-weight: 600">
          Test Yield Rules
        </h1>
        <p style="font-size: 14px; color: var(--black-48)">
          Select a Face and change the options to see the results of your Yield Rules.
        </p>
      </div>

      <div class="col-span-12 px-4 mt-6">
        <pv-divider />
      </div>

      <div v-if="isDirty || !newId" class="col-span-12 mt-6 px-4 text-center">
        <h1>Unsaved changes</h1>
        <p>To continue testing yield rules, save your changes</p>
      </div>

      <template v-if="!isDirty && newId">
        <div class="col-span-6 px-4 mt-6">
          <pv-dropdown
            v-model="selectedFace"
            :options="keyValueToArray(faceList)"
            option-label="value"
            option-value="id"
            placeholder="Select a face"
            class="w-full"
            @update:model-value="getRates(selectedFace)"
          />
        </div>

        <div v-if="isLoading && !Object.keys(relevantDataPoints).length" class="col-span-12 py-2 px-4 mt-8">
          <form-skeleton rows="4" />
        </div>

        <div v-if="Object.keys(relevantDataPoints).length" class="col-span-12 px-4 my-4">
          <h1 class="form-label">
            Options
          </h1>
        </div>

        <div v-if="Object.keys(relevantDataPoints).length" class="col-span-12 py-4 px-4 options-panel">
          <div class="grid grid-cols-12 pt-2 pb-1">
            <div v-for="dataPoint in relevantDataPoints" :key="dataPoint.data" class="col-span-4 px-4 py-4">
              <div class="form-label">
                {{ dataPoint.fieldName }}
              </div>
              <pv-dropdown
                v-if="dataPoint.options"
                v-model="listOfDataPointValues[dataPoint.data]"
                class="w-full"
                :options="keyValueToArray(dataPoint.options)"
                option-label="value"
                option-value="id"
              />

              <div v-else-if="dataPoint.isDate && dataPoint.data === 'end' && doCalculatedEndDate">
                <input-text v-model="listOfDataPointValues[dataPoint.data]" class="w-full" disabled />
              </div>

              <div v-else-if="dataPoint.isDate">
                <Datepicker
                  v-model="listOfDataPointValues[dataPoint.data]"
                  name="startAt"
                  :typeable="true"
                  input-format="yyyy-MM-dd"
                  placeholder="YYYY-MM-DD"
                  class="w-full p-inputtext"
                />
              </div>

              <div v-else>
                <input-text v-model="listOfDataPointValues[dataPoint.data]" class="w-full" />
              </div>
            </div>
          </div>
        </div>

        <button
          v-if="Object.keys(relevantDataPoints).length"
          type="button"
          class="col-span-12 py-2 px-4 mx-4 my-4 inline-flex items-center justify-center rounded-md bg-sky-500 text-xl font-medium font-bold text-white shadow-sm hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          @click="getResults(selectedFace)"
        >
          <p>RUN TEST</p>
        </button>

        <div v-if="isLoading" class="col-span-12 py-2 px-4 mt-8">
          <form-skeleton rows="4" />
        </div>

        <div v-if="!isDirty && !isLoading" class="col-span-12 py-2 px-4 mt-8">
          <h1 class="form-label">
            Results
          </h1>
        </div>

        <div
          v-if="!clickedTest"
          class="col-span-12 py-2 px-4 mx-4 my-4 box-border bg-slate-200 border-radius-1 rounded-md border"
        >
          <p>Run a test with a Face ID to review data.</p>
        </div>

        <div v-if="clickedTest && !isLoading" class="col-span-12 py-4 px-4 options-panel">
          <div class="grid grid-cols-12 pt-2 pb-1">
            <div v-for="(dataPoint, dataId) in listOfRelevantActions" :key="dataId" class="col-span-4 px-4">
              <div class="form-label">
                {{ dataId }}
              </div>
              <input-text :placeholder="dataPoint" class="w-full" disabled />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FormSkeleton from '@apparatix/Components/FormSkeleton.vue';

export default {
  components: { FormSkeleton },
  props: [
    'pricingRules',
    'selectedRules',
    'pricingRateCode',
    'faceList',
    'yieldCodeName',
    'yieldCodeDescription',
    'rulesToSave',
    'recordId',
    'isDirty',
    'newId',
  ],
  emits: ['update:yieldCodeName', 'update:yieldCodeDescription', 'update:rulesToSave'],
  setup(props, ctx) {
    if (props.pricingRateCode) {
      const { name, description } = props.pricingRateCode;

      if (name) {
        ctx.emit('update:yieldCodeName', name);
      }

      if (description) {
        ctx.emit('update:yieldCodeDescription', description);
      }

      if (Array.isArray(props.selectedRules)) {
        ctx.emit('update:rulesToSave', props.selectedRules);
      }
    }
  },
  data() {
    return {
      scopedRateCodeName: this.pricingRateCode.name,
      scopedRateCodeDescription: this.pricingRateCode.description,
      scopedRateCodeId: this.pricingRateCode.id,
      scopedSelectedRules: this.selectedRules,
      scopedPricingRules: this.pricingRules,
      selectedFace: {},
      clickedLoad: false,
      clickedTest: false,
      isSaved: true,
      relevantDataPoints: {},
      listOfDataPointValues: {},
      listOfRelevantActions: {},
      listOfDates: {},
      doCalculatedEndDate: false,
      isValidCaseToCalculateEndDate: false,
      isLoading: false,
    };
  },
  computed: {
    availableRulesCount() {
      if (Array.isArray(this.scopedPricingRules)) {
        return this.scopedPricingRules.length;
      }

      return 0;
    },
    selectedRulesCount() {
      if (Array.isArray(this.scopedSelectedRules)) {
        return this.scopedSelectedRules.length;
      }

      return 0;
    },
  },
  watch: {
    relevantDataPoints(newValue) {
      this.listOfDataPointValues = {};
      for (const value in newValue) {
        this.listOfDataPointValues[newValue[value].data] = newValue[value].base;
      }
    },
    scopedSelectedRules() {
      this.isSaved = false;
      this.clickedLoad = false;
      this.clickedTest = false;
      this.$emit('update:rulesToSave', this.scopedSelectedRules);
    },
    listOfDataPointValues: {
      handler: function() {
        if (this.listOfDataPointValues['interval'] === 'Start/End') {
          this.doCalculatedEndDate = false;
        } else if (this.isValidCaseToCalculateEndDate) {
          this.doCalculatedEndDate = true;
        }
        if (this.doCalculatedEndDate && this.listOfDataPointValues['start'] && this.listOfDataPointValues['interval'] && this.listOfDataPointValues['periods']) {
          this.calculateContractEnd(this.listOfDataPointValues['start'], this.listOfDataPointValues['interval'], this.listOfDataPointValues['periods']);
        }
      },
      deep: true,
    },
  },
  methods: {
    log: function(evt) {
      window.console.log(evt);
    },
    calculateContractEnd: function(contractStart, interval, periods) {
      const splitInterval = interval.split(' ');
      const totalPeriods = splitInterval[0] * periods;
      const endDate = new Date(contractStart);
      if (splitInterval[1].toLowerCase() === 'day') {
        this.listOfDataPointValues['end'] = new Date(endDate.setDate(endDate.getDate() + totalPeriods - 1)).toISOString().split('T')[0];
      } else if (splitInterval[1].toLowerCase() === 'week') {
        const days = totalPeriods * 7;
        this.listOfDataPointValues['end'] = new Date(endDate.setDate(endDate.getDate() + days - 1)).toISOString().split('T')[0];
      } else if (splitInterval[1].toLowerCase() === 'month') {
        this.listOfDataPointValues['end'] = new Date(endDate.setDate(endDate.getDate(endDate.setMonth(endDate.getMonth()+totalPeriods)) - 1)).toISOString().split('T')[0];
      } else if (splitInterval[1].toLowerCase() === 'year') {
        const tempDate = new Date(endDate.setFullYear(endDate.getFullYear() + totalPeriods) - 1);
        this.listOfDataPointValues['end'] = new Date(tempDate.setDate(tempDate.getDate() - 1)).toISOString().split('T')[0];
      } else {
        return;
      }
      // eslint-disable-next-line consistent-return
      return this.listOfDataPointValues['end'];
    },

    getRates: function(face) {
      this.isLoading = true;
      const app = this;
      if (this.isDirty) {
        this.$toast.add({
          severity: 'warn', summary: 'Warning', detail: 'Please save the yield code before proceeding', life: 3000,
        });
      }

      this.clickedLoad = true;

      axios.get('/pricingConditionsAndActions', {
        params: {
          ruleIds: this.scopedSelectedRules.map(rule => rule.id),
          faceId: face,
        },
      })
        .then((response) => {
          const existingDataPoints = response.data.map(((dataPoint) => dataPoint.data));

          // If we have all these parameters lets calculate the end date for them rather than have it as an editable field
          if (existingDataPoints.includes('start') && existingDataPoints.includes('end') && existingDataPoints.includes('interval') && existingDataPoints.includes('periods')) {
            app.doCalculatedEndDate = true;
            app.isValidCaseToCalculateEndDate = true;
          } else {
            app.doCalculatedEndDate = false;
            app.isValidCaseToCalculateEndDate = false;
          }
          app.relevantDataPoints = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          const message = error.message || 'Something went wrong';
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: message, life: 3000,
          });
        });
    },

    getResults: function(face) {
      this.isLoading = true;
      const app = this;

      if (this.isDirty) {
        this.$toast.add({
          severity: 'warn', summary: 'Warning', detail: 'Please save the yield code before proceeding', life: 3000,
        });
      }

      this.clickedTest = true;

      axios.get('/pricingRateCodesCalculator/', {
        params: {
          rateCodeId: this.newId,
          face: face,
          context: this.listOfDataPointValues,
        },
      })
        .then((response) => {
          app.listOfRelevantActions = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    addAllRules() {
      this.scopedSelectedRules = [...this.scopedSelectedRules, ...this.scopedPricingRules];
      this.scopedPricingRules = [];

      this.$emit('update:rulesToSave', this.scopedSelectedRules);
    },
    removeAllRules() {
      this.scopedPricingRules = [...this.scopedPricingRules, ...this.scopedSelectedRules];
      this.scopedSelectedRules = [];

      this.$emit('update:rulesToSave', this.scopedSelectedRules);
    },
    onNameChange(e) {
      this.$emit('update:yieldCodeName', e);
    },
    onDescriptionChange(e) {
      this.$emit('update:yieldCodeDescription', e);
    },
    keyValueToArray(data) {
      if (Array.isArray(data)) return data;

      const entries = [];
      Object.entries(data).forEach(([key, value]) => {
        if (Object.hasOwn(data, key)) {
          entries.push({ id: key, value: value });
        }
      });

      return entries;
    },
  },
};

</script>

<style scoped>
  .list-group-item {
      height: 3.25rem;
  }

  .list-group-item:hover {
      min-height: 3.25rem;
      height: fit-content;
  }

  .text-input-group {
    font-size: 14px;

    label {
      font-weight: 500;
    }
  }

  .form-label {
    font-size: 14px;
    color: var(--black-80);
    font-weight: 500;
  }

  .p-badge {
      background: var(--primary-blue);
  }

  :deep(.p-inputtext .p-inputtext:not(.p-dropdown > .p-inputtext)) {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 14px;
    padding: 0 12px;
    border-radius: 8px;
    border: 1px solid var(--black-16);
    background: var(--white);
    box-shadow: var(--input-box-shadow);
  }

  :deep(.p-inputtextarea) {
    padding: 10px 12px;
    min-height: 72px;
  }

  .drag-container {
    display: flex;
    width: 100%;
    gap: 0;

    .swap-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      flex-shrink: 0;
    }

    .rule-column {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      flex-direction: column;

      .rule-header {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          color: var(--black-80);
          font-size: 14px;
        }

        .all-rules-action {
          color: var(--text-link-primary);
          cursor: pointer;
          flex-grow: 1;
          text-align: right;
        }
      }

      .draggable-container {
        height: 384px;
        width: 100%;
        display: flex;
        margin-top: 16px;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid var(--black-8);
        overflow-y: auto;
        background: var(--black-8);
      }
    }
  }

  .options-panel {
    border-radius: 8px;
    background: linear-gradient(0deg, var(--black-8) 0%, var(--black-8) 100%), var(--white);
  }

 .p-inputtext:enabled:focus {
      border-color: var(--primary-blue);
 }

 .p-inputtext:enabled:hover {
     border-color: var(--primary-blue);
 }

 .p-dropdown:not(.p-disabled):hover {
    border-color: var(--primary-blue);
 }

  .p-dropdown:not(.p-disabled):focus {
    border-color: var(--primary-blue);
 }

</style>
