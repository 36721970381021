<template>
  <div class="py-1 px-6 relative">
    <div class="absolute left-1 top-6 h-9 w-9 bg-gray-200 rounded-full p-2 text-main">
      <slot name="icon" />
    </div>
    <div class="border-2 border-gray-200 rounded-md bg-gray-50 px-9 py-6">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
  },
};
</script>

<style scoped>
</style>
