<template>
  <div class="h-screen">
    <div class="flex flex-col h-full grow">
      <div class="flex flex-row">
        <div class="grid grid-cols-12 basis-full">
          <div class="col-span-12">
            <div class="grid grid-cols-12 px-8 pt-8 pb-4 rounded-t-lg bg-stuart-purple font-inter">
              <div class="col-span-1 font-bold text-white text-2xl">
                <button class="hover:bg-stuart-gray-purple rounded-full text-white" @click="swapComponent('email-schedule', {}, false)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.0"
                    stroke="currentColor"
                    class="w-6 h-6 text-gray-300"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                  </svg>
                </button>
              </div>

              <div class="col-span-11">
                <div class="grid grid-cols-12">
                  <h1 v-if="isCreate" class="col-span-6 pr-2 text-white text-center text-lg font-semibold">
                    Create Email Schedule
                  </h1>
                  <h1 v-else class="col-span-6 pr-2 text-white text-center text-lg font-semibold">
                    Edit Email Schedule
                  </h1>
                  <div class="col-span-6 border-0 px-4 py-2 bg-stuart-darker-purple w-fit h-fit rounded-full text-stuart-gray-purple font-medium text-xs">
                    {{ name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row grow min-h-0">
        <div class="h-full overflow-y-auto scrollbar-hide basis-full">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <p class="bg-stuart-purple px-8 py-4 font-inter text-xs text-gray-200 leading-5 font-normal">
                Create a repeatable, email delivery schedule (exported as an .XLSX file with your current filters applied).
              </p>
            </div>

            <div class="col-span-12 px-8 py-4 ">
              <h3 class="text-gray-400 font-bold text-xs">
                DETAILS
              </h3>
            </div>

            <div class="col-span-12 mt-2 px-8 py-4">
              <div class="grid grid-cols-12">
                <label for="scheduleName" class="col-span-4 text-sm font-medium leading-6 text-gray-900">Schedule Name</label>
                <input
                  id="scheduleName"
                  v-model="modSchedule.name"
                  type="text"
                  name="scheduleName"
                  class="col-span-8 w-full rounded-md border-0 text-gray-900 text-sm shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="The filter name will appear in the subject line"
                >
              </div>
            </div>

            <div class="col-span-12 px-8 py-4">
              <div class="border-t border-gray-300" />
            </div>

            <div class="col-span-12 mt-2 px-8 py-4">
              <div class="grid grid-cols-12">
                <label for="email" class="col-span-4 text-sm font-medium leading-6 text-gray-900">Email Addresses</label>
                <div class="col-span-8 py-2 h-24 overflow-y-scroll w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <div v-if="modSchedule.emailList.length === 0" class="px-2 align-middle text-gray-400 text-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    List of emails
                  </div>
                  <div v-for="(email, index) in modSchedule.emailList" :key="index" class="px-1 pt-1">
                    <div v-if="modSchedule.emailList[index] !== undefined">
                      <div class="rounded-full text-xs w-fit font-normal text-black bg-gray-200 py-1 px-2">
                        {{ email }}
                        <button class="align-middle" @click="deleteEmail(index)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2.0"
                            stroke="currentColor"
                            class="w-5 h-5 text-slate-400 hover:text-slate-500"
                          >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-span-12 px-8 py-4">
              <div class="grid grid-cols-12">
                <div class="col-span-3 pt-2 text-sm font-medium text-gray-900">
                  Add New Email
                </div>
                <button class="col-span-1" @click="addNewEmail(newEmail)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-7 h-7 text-stuart-purple hover:text-indigo-600"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </button>
                <input
                  id="newEmail"
                  v-model="newEmail"
                  type="email"
                  name="newEmail"
                  class="col-span-8 inline-block rounded-md border-0 text-gray-900 text-sm shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Type new email or paste list..."
                  @keydown.enter="onEnter(newEmail)"
                  @keydown.space="onEnter(newEmail)"
                  @keydown.tab.prevent="onEnter(newEmail)"
                >
              </div>
            </div>

            <div v-if="hasExternalEmails" class="col-span-12 mt-8 bg-stuart-notice text-stuart-notice-caution">
              <div class="grid grid-cols-12 px-8 py-8">
                <div class="col-span-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="col-span-10">
                  Emails will be sent to external addresses ({{ createString(externalEmails) }})
                </div>
              </div>
            </div>
            <div v-else class="col-span-12 mt-8" />

            <div class="col-span-12 pb-4">
              <div class="border-t border-gray-300" />
            </div>

            <h3 class="col-span-12 px-8 py-4 text-gray-400 font-bold text-xs">
              CRITERIA
            </h3>

            <div class="col-span-12 mt-2 px-8 pt-2">
              <div class="grid grid-cols-12">
                <div class="col-span-4">
                  Current Filters
                </div>
                <div class="col-span-8">
                  <div v-for="(value, key) in usedFilters" :key="key" class="text-indigo-600 text-sm my-1 px-2 py-2 w-fit rounded-full bg-stuart-gray-purple">
                    <span class="font-semibold">
                      {{ key }}:
                    </span>
                    <span>
                      {{ value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-12 px-8 py-4">
              <div class="border-t border-gray-300" />
            </div>

            <div class="col-span-12 mt-2 px-8 py-4">
              <div class="grid grid-cols-12">
                <div class="col-span-4">
                  Start Date
                </div>
                <div class="col-span-8 inline-block w-40 -my-3 py-1">
                  <Datepicker
                    v-model="modSchedule.startDate"
                    input-format="M/d/yy"
                    name="startDate"
                    class="text-black w-full p-2 focus:ring-indigo-600 focus:border-indigo-600 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-12 px-8 py-4">
              <div class="border-t border-gray-300" />
            </div>

            <div class="col-span-12 mt-2 px-8 py-4">
              <div class="grid grid-cols-12">
                <div class="col-span-4">
                  Delivery Time
                </div>
                <select
                  id="deliveryTime"
                  v-model="modSchedule.deliveryTime"
                  name="deliveryTime"
                  class="col-span-8 inline-block w-7/12 -my-2 py-2 px-2 focus:ring-indigo-600 focus:border-indigo-600 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option v-for="value, index in getListOfTimes()" :key="index">
                    {{ value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-span-12 px-8 py-4">
              <div class="border-t border-gray-300" />
            </div>

            <div class="col-span-12 mt-2 px-8 py-4">
              <div class="grid grid-cols-12">
                <div class="col-span-4">
                  Frequency
                </div>
                <div class="col-span-8 flex items-center pb-4">
                  <input
                    type="radio"
                    :checked="miscObject.frequencyKey === false"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('frequencyKey', false)"
                  >
                  <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">One time delivery</label>
                </div>

                <div class="col-span-4" />
                <div class="col-span-8 flex items-center pb-4">
                  <input
                    type="radio"
                    :checked="miscObject.frequencyKey === true"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('frequencyKey', true)"
                  >
                  <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">Repeat every </label>
                  <select
                    id="type"
                    v-model="miscObject.repeatQuantity"
                    name="type"
                    autocomplete="type-options"
                    class="inline-block w-3/12 ml-4 -my-2 p-2 focus:ring-indigo-600 focus:border-indigo-600 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option v-for="value in 99" :key="value">
                      {{ value }}
                    </option>
                  </select>
                  <select
                    id="type"
                    v-model="miscObject.repeatTime"
                    name="type"
                    autocomplete="type-options"
                    class="inline-block ml-4 -my-2 py-2 pr-8 focus:ring-indigo-600 focus:border-indigo-600 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option v-for="value in ['day', 'week', 'month']" :key="value">
                      {{ setUpperCase(value) }}
                    </option>
                  </select>
                </div>

                <div v-if="miscObject.repeatTime.toLowerCase() === 'week' && miscObject.frequencyKey === true" class="col-span-4" />
                <div v-if="miscObject.repeatTime.toLowerCase() === 'week' && miscObject.frequencyKey === true" class="col-span-8 flex items-center pb-4">
                  <div class="days-of-week">
                    <input
                      id="sun"
                      v-model="miscObject.daysOfWeek.sun"
                      type="checkbox"
                      name="sun"
                      @click="selectDay('sun', ! miscObject.daysOfWeek.sun)"
                    >
                    <label for="sun">Su</label>
                    <input
                      id="mon"
                      v-model="miscObject.daysOfWeek.mon"
                      type="checkbox"
                      name="mon"
                      @click="selectDay('mon', ! miscObject.daysOfWeek.mon)"
                    >
                    <label for="mon">Mo</label>
                    <input
                      id="tue"
                      v-model="miscObject.daysOfWeek.tue"
                      type="checkbox"
                      name="tue"
                      @click="selectDay('tue', ! miscObject.daysOfWeek.tue)"
                    >
                    <label for="tue">Tu</label>
                    <input
                      id="wed"
                      v-model="miscObject.daysOfWeek.wed"
                      type="checkbox"
                      name="wed"
                      @click="selectDay('wed', ! miscObject.daysOfWeek.wed)"
                    >
                    <label for="wed">We</label>
                    <input
                      id="thu"
                      v-model="miscObject.daysOfWeek.thu"
                      type="checkbox"
                      name="thu"
                      @click="selectDay('thu', ! miscObject.daysOfWeek.thu)"
                    >
                    <label for="thu">Th</label>
                    <input
                      id="fri"
                      v-model="miscObject.daysOfWeek.fri"
                      type="checkbox"
                      name="fri"
                      @click="selectDay('fri', miscObject.daysOfWeek.fri)"
                    >
                    <label for="fri">Fr</label>
                    <input
                      id="sat"
                      v-model="miscObject.daysOfWeek.sat"
                      type="checkbox"
                      name="sat"
                      @click="selectDay('sat', miscObject.daysOfWeek.sat)"
                    >
                    <label for="sat">Sa</label>
                  </div>
                </div>

                <div v-if="miscObject.repeatTime.toLowerCase() === 'month' && miscObject.frequencyKey === true" class="col-span-4" />
                <div v-if="miscObject.repeatTime.toLowerCase() === 'month' && miscObject.frequencyKey === true" class="col-span-8 flex items-center pb-4">
                  <input
                    type="radio"
                    :checked="miscObject.monthKey === 'start'"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('monthKey', 'start')"
                  >
                  <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">Start of Month</label>
                  <span class="px-8" />
                  <input
                    type="radio"
                    :checked="miscObject.monthKey === 'end'"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('monthKey', 'end')"
                  >
                  <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">End of Month</label>
                </div>
              </div>
            </div>

            <div v-show="miscObject.frequencyKey" class="col-span-12 px-8 py-4">
              <div class="border-t border-gray-300" />
            </div>

            <div v-show="miscObject.frequencyKey" class="col-span-12 mt-2 px-8 py-4 mb-4">
              <div class="grid grid-cols-12">
                <div class="col-span-4 in">
                  End Date
                </div>
                <div class="col-span-8 pb-2 flex items-center">
                  <input
                    type="radio"
                    :checked="miscObject.endDateKey === null"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('endDateKey', null)"
                  >
                  <label class="pl-4 block text-sm font-medium leading-6 text-gray-900">No end date</label>
                </div>

                <div class="col-span-4" />
                <div class="col-span-8 flex items-center py-2 pt-2">
                  <input
                    name="endDate"
                    type="radio"
                    :checked="miscObject.endDateKey === 'occurrences'"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('endDateKey', 'occurrences')"
                  >
                  <label class="pl-4 block text-sm font-medium leading-6 text-gray-900">End after </label>
                  <select
                    id="type"
                    v-model="miscObject.occurrences"
                    name="type"
                    autocomplete="type-options"
                    class="inline-block w-3/12 ml-2 focus:ring-indigo-600 focus:border-indigo-600 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option v-for="value in 99" :key="value">
                      {{ value }}
                    </option>
                  </select>
                  <label class="ml-3 block text-sm font-medium leading-6 text-gray-900"> occurrence(s)</label>
                </div>
                <div class="col-span-4" />
                <div class="col-span-8 flex items-center py-2">
                  <input
                    name="endDate"
                    type="radio"
                    :checked="miscObject.endDateKey === 'date'"
                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    @click="changeKey('endDateKey', 'date')"
                  >
                  <label class="pl-4 block text-sm font-medium leading-6 text-gray-900">End by </label>
                  <div class="inline-block w-40 -my-2 py-2 pl-4 ">
                    <Datepicker
                      v-model="modSchedule.endDate"
                      input-format="M/d/yy"
                      name="endDate"
                      class="text-black w-full mt-1 p-2 focus:ring-indigo-600 focus:border-indigo-600 shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col h-min">
        <div class="flex flex-row">
          <div class="grid grid-cols-12 basis-full">
            <div class="col-span-12 bg-white border-t border-gray-300 rounded-b-lg px-8 py-4">
              <div class="grid grid-cols-12">
                <div class="col-span-6">
                  <button type="button" class="rounded-md bg-white w-11/12 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:white outline outline-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-gray-100" @click="swapComponent('email-schedule', {}, false)">
                    Cancel
                  </button>
                </div>
                <div class="col-span-6 flex place-content-end">
                  <button
                    v-if="isCreate"
                    type="button"
                    class="rounded-md bg-stuart-purple w-11/12 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click="createSchedule(childData.schedule, modSchedule, miscObject)"
                  >
                    Create
                  </button>
                  <button
                    v-else
                    type="button"
                    class="rounded-md bg-stuart-purple w-11/12 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click="saveSchedule(childData.schedule, modSchedule, miscObject)"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'today',
    'swapComponent',
    'reportName',
    'data',
    'childData',
    'filters',
    'menuAutoinc',
    'validEmailList',
    'timezone',
  ],
  data: function() {
    window.scrollTo(0, 0);
    const today = this.$dateFns.parse(this.today, 'yyyy-MM-dd', new Date());
    const numberListData = this.arrayRange(1, 100, 1);

    const modData = {
      name: '',
      emailList: [],
      startDate: today,
      endDate: null,
      deliveryTime: null,
      frequency: {},
    };

    if (! this.childData.isCreate) {
      modData.name = this.childData.schedule.name;
      modData.emailList = (this.childData.schedule.email_list).split(',');
      modData.startDate = this.$dateFns.parse(this.childData.schedule.start_date, 'yyyy-MM-dd', new Date());
      modData.endDate = this.childData.schedule.end_date;// this.$dateFns.parse(this.childData.schedule.end_date, 'yyyy-MM-dd', new Date());
      modData.deliveryTime = this.getFormattedDeliveryTime(this.childData.schedule.delivery_time, this.timezone);
      modData.frequency = JSON.parse(this.childData.schedule.frequency);
    }

    return {
      startDate: today,
      numberList: numberListData,
      name: this.reportName,
      isCreate: this.childData.isCreate,
      modSchedule: modData,
      miscObject: this.setMiscObjectFromData(modData, this.childData.isCreate),
      newEmail: '',
      usedFilters: this.childData.isCreate ? this.mapToFilterNames(this.filters) : this.mapToFilterNames(this.childData.schedule.view_filter.filter_json),
      validEmails: this.validEmailList,
      externalEmails: modData.emailList.filter(item => this.validEmailList.indexOf(item) === -1),
      hasExternalEmails: (modData.emailList.filter(item => this.validEmailList.indexOf(item) === -1)).length !== 0,
      userTimezone: this.timezone,
      timer: null,
    };
  },
  watch: {
    'modSchedule.emailList': {
      handler: function(newValue) {
        this.externalEmails = newValue.filter(item => this.validEmails.indexOf(item) === -1);
        this.hasExternalEmails = this.externalEmails.length !== 0;
      },
      deep: true,
    },
  },
  methods: {
    addNewEmail: function(email) {
      if (email) {
        this.modSchedule.emailList.push(email);
        this.newEmail = '';
      }
    },
    arrayRange: function(start, stop, step) {
      return Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step,
      );
    },
    closeModal: function() {
      window.parent.document.dispatchEvent(
        new CustomEvent('closeReportScheduleModalEvent', {}),
      );
    },
    createString: function(list) {
      return list.join(', ');
    },
    changeKey: function(key, newValue) {
      this.miscObject[key] = newValue;
    },
    createSchedule: function(schedule, modSchedule, miscObject) {
      const filterObject = {
        filter_json: this.filters,
      };
      const scheduleData = this.setScheduleData(modSchedule, miscObject);

      const apxData = {
        name: modSchedule.name,
        email_list: modSchedule.emailList,
        start_date: modSchedule.startDate,
        end_date: scheduleData.endDate,
        delivery_time: modSchedule.deliveryTime,
        frequency: scheduleData.frequency,
        autoinc: this.menuAutoinc,
        view_filter: filterObject,
        endDateKey: scheduleData.endDateKey,
        monthKey: scheduleData.monthKey,
      };
      axios.post('/report-schedules/create-email-schedule', apxData)
        .catch(error => {
          toast('warning', error.response.data.message);
        })
        .then(response => {
          toast('success', response.data.message);
          this.isCreate = false;
          // eslint-disable-next-line vue/no-mutating-props
          this.data.push(response.data.data);
          // eslint-disable-next-line vue/no-mutating-props
          this.childData.schedule.id = response.data.data.id;
        });
    },
    deleteEmail: function(emailIndex) {
      delete this.modSchedule.emailList[emailIndex];
      this.modSchedule.emailList = this.modSchedule.emailList.filter(element => element !== emailIndex);
    },
    getFormattedDeliveryTime: function(time, timezone) {
      let deliveryTime;

      const dateTimeSplit = time.split(' ');
      const timeSplit = dateTimeSplit[1].split(':') ?? [];
      if (timeSplit[0] < 12) {
        if (Number(timeSplit[0]) === 0) {
          timeSplit[0] = 12;
        }
        deliveryTime = timeSplit[0] + ':' + timeSplit[1] + ' am';
      } else {
        if (Number(timeSplit[0]) === 12) {
          deliveryTime = timeSplit[0] + ':' + timeSplit[1] + ' pm';
        } else {
          deliveryTime = timeSplit[0] % 12 + ':' + timeSplit[1] + ' pm';
        }
      }

      return deliveryTime + ' ' + timezone;
    },
    getListOfTimes: function() {
      // copied from https://stackoverflow.com/questions/36125038/generate-array-of-times-as-strings-for-every-x-minutes-in-javascript
      // modified lines 270-276 start to allow 12:00 am instead of 00:00 am; changed hh % 12 to floor on line 278
      const x = 30; // minutes interval
      const times = []; // time array
      let tt = 0; // start time
      const ap = ['am', 'pm']; // AM-PM

      // loop to increment the time and push results in array
      for (let i=0; tt<24*60; i++) {
        const hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        let floor = hh;
        if (hh % 12 !== 0) {
          floor = hh % 12;
        }
        if (hh === 0) {
          floor = 12;
        }
        const mm = (tt%60); // getting minutes of the hour in 0-55 format
        times[i] = (floor) + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh/12)] + ' ' + this.userTimezone; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
      }

      return times;
    },
    mapToFilterNames: function(filters) {
      const parsedFilters = JSON.parse(filters);
      const keyFilterPair = {
        fdivision: 'Division',
        foffice: 'Office',
        facquisition: 'Acquisition',
        fbuytype: 'Purchase Source',
        fcategory: 'Contract Category',
        finv_number: 'Face Number',
        falloc_advertiser: 'Advertiser',
        fsic: 'SIC',
        finv_description: 'Inventory Description',
        ftype: 'Inventory Venue',
        fmarket: 'Market',
        fae: 'AE',
        finv_priceclass: 'Inventory Rate',
        finv_ratecode: 'Rate Code',
        finv_city: 'City',
        finv_county: 'County',
        f_inv_county: 'County',
        fstate: 'State',
        finv_zip: 'Zip',
        fpolylist: 'Geographic Filter',
      };
      let temp = '';
      let returnObj = {};
      for (const key in parsedFilters) {
        temp = keyFilterPair[key];
        returnObj[temp] = parsedFilters[key].replaceAll('|', ', ').replaceAll('$', '').replaceAll('^', '');
      }

      if (Object.keys(returnObj).length === 0) {
        returnObj = {
          Filters: 'No filter selected.',
        };
      }
      return returnObj;
    },
    onEnter: function(submittedEmails) {
      const emailList = submittedEmails.replaceAll(' ', ',').split(',');
      emailList.forEach((element) => element ? this.modSchedule.emailList.push(element) : '');
      this.newEmail = '';
    },
    saveSchedule: function(schedule, modSchedule, miscObject) {
      const id = this.childData.schedule.id;
      const scheduleData = this.setScheduleData(modSchedule, miscObject);

      const apxData = {
        name: modSchedule.name,
        email_list: modSchedule.emailList,
        start_date: modSchedule.startDate,
        end_date: scheduleData.endDate,
        delivery_time: modSchedule.deliveryTime,
        frequency: scheduleData.frequency,
        endDateKey: scheduleData.endDateKey,
        monthKey: scheduleData.monthKey,
      };
      axios.put(`/report-schedules/update-email-schedule/${id}`, apxData)
        .catch(error => {
          toast('warning', error.response.data.message);
        })
        .then(response => {
          toast('success', response.data.message);
          // eslint-disable-next-line vue/no-mutating-props
          this.data[this.data.findIndex(x => x.id === response.data.data.id)] = response.data.data;
        });
    },
    selectDay: function(dayOfWeek, isSelected) {
      this.miscObject.daysOfWeek[dayOfWeek] = isSelected;
    },
    setMiscObjectFromData: function(data, isCreate) {
      const miscObject = {
        endDateKey: null,
        frequencyKey: false,
        monthKey: null,
        repeatTime: 'Day',
        occurrences: 1,
        repeatQuantity: 1,
        daysOfWeek: {
          sun: false,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
        },
      };

      if (isCreate) {
        return miscObject;
      }

      if (data.frequency.occurrences !== undefined) {
        miscObject.endDateKey = 'occurrences';
        miscObject.occurrences = data.frequency.occurrences;
        miscObject.endDate = null;
      }

      if (data.frequency.occurrences === undefined && data.endDate !== null) {
        miscObject.endDateKey = 'date';
        data.endDate = this.$dateFns.parse(data.endDate, 'yyyy-MM-dd', new Date());
        // Do we save an end date if occurrences?
      }

      if (data.frequency.isOneTimeDelivery === false) {
        miscObject.frequencyKey = true;
        miscObject.repeatTime = this.setUpperCase(data.frequency.repeatEvery.period);
        miscObject.repeatQuantity = data.frequency.repeatEvery.interval;
        miscObject.endDate = null;
      }

      if (
        typeof data?.frequency === 'object' &&
        typeof data?.frequency?.repeatEvery === 'object' &&
        Object.hasOwn(data.frequency, 'repeatEvery') &&
        Object.hasOwn(data.frequency.repeatEvery, 'period')
      ) {
        if (data.frequency.repeatEvery.period === 'week') {
          miscObject.daysOfWeek = {
            sun: data.frequency.repeatEvery.daysOfWeek.includes('Sun'),
            mon: data.frequency.repeatEvery.daysOfWeek.includes('Mon'),
            tue: data.frequency.repeatEvery.daysOfWeek.includes('Tue'),
            wed: data.frequency.repeatEvery.daysOfWeek.includes('Wed'),
            thu: data.frequency.repeatEvery.daysOfWeek.includes('Thu'),
            fri: data.frequency.repeatEvery.daysOfWeek.includes('Fri'),
            sat: data.frequency.repeatEvery.daysOfWeek.includes('Sat'),
          };
        }

        if (data.frequency.repeatEvery.period === 'month')
        {
          if (data.frequency.repeatEvery.isStartOfMonth) {
            miscObject.monthKey = 'start';
          } else if (! data.frequency.repeatEvery.isStartOfMonth) {
            miscObject.monthKey = 'end';
          } else {
            miscObject.monthKey = null;
          }
        }
        else
        {
          miscObject.monthKey = null;
        }
      }

      return miscObject;
    },
    setScheduleData: function(modSchedule, miscObject) {
      if (miscObject.endDateKey === 'occurrences') {
        modSchedule.endDate = null;
        modSchedule.frequency.occurrences = miscObject.occurrences;
      }
      if (miscObject.endDateKey === null) {
        modSchedule.endDate = null;
        delete modSchedule.frequency.occurrences;
      }
      if (miscObject.endDateKey === 'date') {
        delete modSchedule.frequency.occurrences;
      }
      modSchedule.endDateKey = miscObject.endDateKey;

      if (miscObject.frequencyKey === false) {
        modSchedule.frequency = {
          isOneTimeDelivery: true,
        };
      } else {
        modSchedule.frequency.repeatEvery = {};
        modSchedule.frequency.isOneTimeDelivery = false;
        modSchedule.frequency.repeatEvery = {
          period: miscObject.repeatTime.toLowerCase(),
          interval: miscObject.repeatQuantity,
        };
        if (miscObject.repeatTime.toLowerCase() === 'week') {
          const listOfDays = [];
          Object.entries(miscObject.daysOfWeek).forEach(entry => {
            const [key, value] = entry;
            if (value === true) {
              listOfDays.push(this.setUpperCase(key));
            }
          });
          modSchedule.frequency.repeatEvery.daysOfWeek = listOfDays;
        } else if (miscObject.repeatTime.toLowerCase() === 'month') {
          modSchedule.monthKey = miscObject.monthKey;
          modSchedule.frequency.repeatEvery.isStartOfMonth = miscObject.monthKey === 'start';
        }
      }

      return modSchedule;
    },
    setUpperCase: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style scoped>
.days-of-week input {
    display: none;
}

.days-of-week label {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    line-height: 40px;
    cursor: pointer
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#sun:checked ~ label[for="sun"],
#mon:checked ~ label[for="mon"],
#tue:checked ~ label[for="tue"],
#wed:checked ~ label[for="wed"],
#thu:checked ~ label[for="thu"],
#fri:checked ~ label[for="fri"],
#sat:checked ~ label[for="sat"] {
    background: #e8eaff;
    color: #4050e4;
}
</style>
