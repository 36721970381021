<template>
  <!--    <keep-alive>-->
  <component :is="currentComponent" :swap-component="swapComponent" :child-data="childData" />
<!--    </keep-alive>-->
</template>

<script>
import EmailSchedule from './EmailSchedule.vue';
import CreateEmailSchedule from './CreateEmailSchedule.vue';

export default {
  components: {
    'email-schedule': EmailSchedule,
    'create-email-schedule': CreateEmailSchedule,
  },
  props: [
    'component',
  ],
  data: function() {
    return {
      currentComponent: this.component,
      childData:  {
        isCreate: true,
      },
    };
  },
  methods: {
    closeModal: function() {
      // Linting doesn't allow for empty functions, but there may be external code that calls this
      // so instead, intentional no-op for a function body to prevent potential undefined error
      Function.prototype();
    },
    swapComponent: function(component, schedule, isCreate) {
      this.childData = {
        schedule: schedule,
        isCreate: isCreate,
      };
      this.currentComponent = component;
    },
  },
};
</script>

<style scoped>

</style>
