<!-- eslint-disable vue/no-textarea-mustache -->
<template>
  <form id="artRequestForm" class="flex-1 py-8">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <header>
        <div class="text-gray-500">
          <h5 class="inline-block font-semibold">
            Project ID:
          </h5> {{ artRequest.reqId }}
        </div>
        <h1 class="pt-2 text-4xl font-medium font-bold text-gray-900">
          {{ artRequest.advertiser.c2_name }} ({{ artRequest.advertiser.c2_id }})
        </h1>
        <div class="pt-2">
          <h5 class="inline-block font-semibold">
            Status:
          </h5>
          <select
            id="status"
            v-model="artRequest.reqStatus"
            name="status"
            autocomplete="status-options"
            class="inline-block ml-2 -my-2 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          >
            <option v-for="option, idx in statusOptions" :key="idx">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="pt-2">
          <h5 class="inline-block font-semibold">
            Creative Request Type:
          </h5>
          <select
            id="type"
            v-model="artRequest.creative_brief.type"
            name="type"
            autocomplete="type-options"
            class="inline-block w-full ml-2 -my-2 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          >
            <option value="null" disabled hidden>
              &mdash;
            </option>
            <option v-for="option, idx in briefType" :key="idx">
              {{ option }}
            </option>
          </select>
        </div>
      </header>
      <div class="mt-8 grid gap-5 sm:grid-cols-1 lg:grid-cols-3">
        <div>
          <h3 class="text-gray-500 text-2xl font-medium font-bold texty-gray-500">
            Dates
          </h3>
          <div class="mt-3 px-3 py-6 bg-gray-200 rounded-lg">
            <div>
              <h5 class="inline-block pr-1 font-semibold">
                Date Created:
              </h5><span class="text-gray-500">{{ artRequest.reqCreateDate || '&mdash;' }}</span>
            </div>
            <div class="pt-1">
              <h5 class="inline-block pr-1 font-semibold">
                Production<br>Deadline:
              </h5>
              <div class="inline-block w-40 -my-2 py-2 pl-1 ">
                <Datepicker
                  v-model="artRequest.reqStartDate"
                  input-format="MMMM do, yyyy"
                  placeholder="Select Date"
                  name="deadlineDate"
                  class="text-gray-500 w-full mt-1 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Posting Date:
              </h5>
              <div class="inline-block w-40 -my-3 py-1 pl-1 ">
                <Datepicker
                  v-model="artRequest.reqDueDate"
                  input-format="MMMM do, yyyy"
                  placeholder="Select Date"
                  name="postingDate"
                  class="text-gray-500 w-full mt-1 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Creative Deadline:
              </h5><span class="text-gray-500">{{ artRequest.creative_brief.creative_date || '&mdash;' }}</span>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Initial Review Date:
              </h5>
              <div class="inline-block w-40 -my-3 py-1 pl-1 ">
                <Datepicker
                  v-model="artRequest.reqInitialReviewDate"
                  input-format="MMMM do, yyyy"
                  placeholder="Select Date"
                  name="initialReviewDate"
                  class="text-gray-500 w-full mt-1 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-gray-500 text-2xl font-medium font-bold texty-gray-500">
            People
          </h3>
          <div class="mt-3 px-3 py-6 bg-gray-200 rounded-lg">
            <div class="">
              <h5 class="inline-block pr-1 font-semibold">
                Account Executive:
              </h5><span class="text-gray-500">{{ artRequest.ae.user_name || '&mdash;' }}</span>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Assigned To:
              </h5>
              <select
                id="assignee"
                v-model="artRequest.reqAssignedTo"
                name="assignee"
                autocomplete="status-options"
                class="inline-block ml-2 -my-2 p-1 text-gray-500 focus:ring-red-500 focus:border-red-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" disabled hidden>
                  &mdash;
                </option>
                <option />
                <option v-for="option in assignees" :key="option.user_userid" :value="option.user_userid">
                  {{ option.user_name }}
                </option>
              </select>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Department:
              </h5><span class="text-gray-500">{{ artRequest.reqDepartment || '&mdash;' }}</span>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Office:
              </h5><span class="text-gray-500">{{ artRequest.reqOffice || '&mdash;' }}</span>
            </div>
            <br>
          </div>
        </div>
        <div>
          <h3 class="text-gray-500 text-2xl font-medium font-bold texty-gray-500">
            Details
          </h3>
          <div class="mt-3 px-3 py-6 bg-gray-200 rounded-lg">
            <div class="">
              <h5 class="inline-block pr-1 font-semibold">
                Contract:
              </h5><span class="underline text-red-500"><a :href="'/util/get/getContractPDFfromCFS.php?recordId=' + artRequest.reqContractId" target="_blank">{{ artRequest.reqContractId || '&mdash;' }}</a></span>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Prepay:
              </h5><span class="text-gray-500">{{ artRequest.advertiser.c2_options.includes('Prepay Only') ? 'Yes' : 'No' }}</span>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                Market:
              </h5><span class="text-gray-500">{{ artRequest.reqMarket || '&mdash;' }}</span>
            </div>
            <div class="pt-2">
              <h5 class="inline-block pr-1 font-semibold">
                City Approval:
              </h5><span class="text-gray-500">{{ artRequest.reqCityApproval || '&mdash;' }}</span>
            </div>
            <br>
          </div>
        </div>
      </div>

      <div class="mt-8 grid gap-5 sm:grid-cols-1 lg:grid-cols-1">
        <div>
          <h3 class="grid grid-cols-1 text-gray-500 text-2xl font-medium font-bold texty-gray-500">
            Request to Expedite
          </h3>
          <div class="relative flex items-start">
            <div class="pt-1">
              <h5 class="inline-block font-semibold">
                Requested Due Date:
              </h5>
              <div class="inline-block -my-2 p-1 ">
                <Datepicker
                  v-model="artRequest.creative_brief.expedite_date"
                  input-format="MMMM do, yyyy"
                  placeholder="Select Date"
                  name="expediteDate"
                  clearable="true"
                  :disabled="hasExpediteAccess != true"
                  class="text-gray-500 focus:ring-red-500 focus:border-red-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
          <textarea
            v-show="expediteIsActive"
            id="about"
            v-model="artRequest.creative_brief.expedite_description"
            input="disabled"
            placeholder="Explanation for request. Required."
            name="expediteReason"
            class="grid grid-cols-1 mt-3 h-40 shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md overflow-y-auto"
            required
          />
        </div>
      </div>

      <div class="block mt-8">
        <h3 class="grid grid-cols-1 text-gray-500 text-2xl font-medium font-bold texty-gray-500">
          Description
        </h3>
        <textarea
          id="about"
          v-model="reqBrief"
          input="disabled"
          name="description"
          class="grid grid-cols-1 mt-3 h-40 shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md overflow-y-auto"
        />
      </div>


      <div v-for="(value, index) in filteredBriefs" :key="index">
        <h3
          class="grid grid-cols-1 mt-8 text-2xl font-medium font-bold"
          :class="{
            'text-red-500': isValidField(value.selectionValue),
            'text-gray-500': ! isValidField(value.selectionValue),
          }"
        >
          {{ value.selection }}
        </h3>
        <p class="text-gray-500 font-medium text-sm italic">
          {{ value.metaData }}
        </p>
        <div v-if="value.selectionMeta1 === 'String'">
          <textarea
            id="about"
            v-model.trim="artRequest.reqBriefCustomFields[value.selectionValue]"
            input="disabled"
            name="description"
            class="grid grid-cols-1 mt-3 h-40 shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md overflow-y-auto"
          >{{ artRequest.reqBriefCustomFields[value.selectionValue] }} {{ value.selectionValue }}</textarea>
        </div>
        <div v-else-if="value.selectionMeta1 === 'Boolean'">
          <div class="relative flex mt-3 items-start">
            <input
              id="boolean"
              v-model="artRequest.reqBriefCustomFields[value.selectionValue]"
              type="checkbox"
              class="h-4 w-4 mt-1 rounded border-gray-300 text-red-500 focus:ring-red-500"
            >
            <label for="boolean" class="ml-1 font-medium text-base text-gray-900">Active</label>
          </div>
        </div>
        <div v-else-if="value.selectionMeta1 === ('Integer' || 'Float')">
          <div class="grid grid-cols-3">
            <input v-model.number="artRequest.reqBriefCustomFields[value.selectionValue]" type="number" class="w-fit mt-3 shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md">
          </div>
        </div>
        <div v-else-if="value.selectionMeta1 === 'List'">
          <div class="grid grid-cols-2">
            <select v-model="artRequest.reqBriefCustomFields[value.selectionValue]" class="col-span-1 mt-3 shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md">
              <option value="" disabled hidden>
                &mdash;
              </option>
              <option v-for="option, idx in value.list" :key="idx">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div v-else-if="value.selectionMeta1 === 'Date'">
          <div class="grid grid-cols-4">
            <Datepicker
              v-model="artRequest.reqBriefCustomFields[value.selectionValue]"
              input-format="MMMM do, yyyy"
              placeholder="Select Date"
              name="deadlineDate"
              class="text-gray-500 w-full mt-1 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div v-else>
          <textarea
            id="about"
            v-model.trim="artRequest.reqBriefCustomFields[value.selectionValue]"
            input="disabled"
            name="description"
            class="grid grid-cols-1 mt-3 h-40 shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md overflow-y-auto"
          >{{ artRequest.reqBriefCustomFields[value.selectionValue] }} {{ value.selectionValue }}</textarea>
        </div>
      </div>


      <div class="mt-8">
        <h3 class="text-gray-500 text-2xl font-medium font-bold texty-gray-500">
          Production Needs
        </h3>
        <div class="mt-3 p-3 shadow-sm h-40 block w-full whitespace-pre-wrap sm:text-sm border border-gray-300 rounded-md overflow-y-auto">
          <div v-for="(value, index) in productionInfo.productionInfo" :key="index">
            <h3>
              {{ info[index] }}
              <a :href="'../apx/photoSheet/doc.share?list=' + invIdList[index]" target="_blank" class="underline text-red-500">{{ faceList[index] }}</a>
            </h3>
          </div>
          <h3>{{ prod }}</h3>
        </div>
      </div>
      <div class="mt-8">
        <h3 class="text-gray-500 text-2xl font-medium font-bold inline-block">
          Files
        </h3>
        <span class="float-right text-gray-500">
          Image View
          <select
            v-model="imageView"
            class="inline-block ml-2 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md pr-8"
          >
            <option
              v-for="(option, key) in imageViewOptions"
              :key="key"
              :value="key"
              selected="imageView == key ? 'selected' : ''"
            >
              {{ option }}
            </option>
          </select>
        </span>
        <div class="overflow-hidden">
          <TransitionGroup name="list">
            <div
              v-for="(file, index) in files"
              :key="index"
              class="mt-6"
              data-attachment-id="{{file.reqa_id}}"
            >
              <div class="mt-4 text-gray-500">
                <h5 class="inline-block font-semibold">
                  File:
                </h5> <a
                  :href="file.filePath"
                  :download="file.reqa_filename"
                  :file-size="file.reqa_filesize"
                  class="underline text-red-500"
                >{{ file.reqa_filename }}</a> /
                <h5 class="inline-block font-semibold">
                  Date:
                </h5> {{ file.reqa_created_date }} /
                <h5 class="inline-block font-semibold">
                  Type:
                </h5>
                <select
                  v-model="file.reqa_attachment_type"
                  autocomplete="attachment-types"
                  class="inline-block ml-2 p-1 focus:ring-red-500 focus:border-red-500 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  @change="updateAttachment(file)"
                >
                  <option v-for="option, idx in attachmentTypes" :key="idx" selected="(file.reqa_attachment_type == option) ? 'selected' : ''">
                    {{ option }}
                  </option>
                </select>
                <a href="" @click.prevent="deleteAttachment(file, index)"><img class="inline-block ml-2" src="/res/img/error-24.png" title="delete"></a>
              </div>
              <img
                v-if="imageView != 'none'"
                style="max-height: 450px"
                alt="Image Not Found"
                :src="thumbsrc(file)"
              >
            </div>
          </TransitionGroup>
        </div>
        <div class="mt-6">
          <button
            v-show="!isAddingFile"
            type="submit"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            @click.prevent="isAddingFile = !isAddingFile"
          >
            Add File
          </button>
          <div v-show="isAddingFile">
            <AddFile
              :allowed-formats="allowedExtensions"
              :art-request-id="artRequest.reqId"
              @file-uploaded="fileUploadHandler"
            />
          </div>
        </div>
      </div>
      <div class="mt-8">
        <h3 class="text-gray-500 text-2xl font-medium font-bold texty-gray-500">
          History
        </h3>
        <div class="mt-3 p-4 h-40 shadow-sm block w-full whitespace-pre-wrap sm:text-sm border border-gray-300 rounded-md overflow-y-auto">
          {{ artRequest.reqHistory }}
        </div>
        <textarea class="hidden" name="history" :value="artRequest.reqHistory" />
      </div>
      <div class="mt-8 grid grid-cols-6">
        <div class="col-span-5">
          <input v-model="comment" type="text" class="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
        </div>
        <div class="col-span-1">
          <div class="py-1 pl-8">
            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click.prevent="addComment('')">
              Add Comment
            </button>
          </div>
        </div>
      </div>
      <input
        id="saveStatus"
        v-model="isSaved"
        type="hidden"
        name="saveStatus"
      >
    </div>
  </form>
</template>

<script>
export default {
  props: {
    user: Object,
    artRequestData: Object,
    productionInfo: Object,
    attachments: Object,
    assignee: Object,
    assignees: Array,
    customFieldsData: Object,
    statusOptions: Array,
    attachmentTypes: Array,
    allowedExtensions: String,
    csrf: String,
    briefType: Object,
  },
  data: function() {
    const artRequest = this.artRequestData;
    artRequest.reqStartDate = this.$dateFns.parse(artRequest.reqStartDate, 'yyyy-MM-dd', new Date());
    artRequest.reqDueDate = this.$dateFns.parse(artRequest.reqDueDate, 'yyyy-MM-dd', new Date());
    artRequest.reqInitialReviewDate = this.$dateFns.parse(artRequest.reqInitialReviewDate, 'yyyy-MM-dd', new Date());

    if (artRequest.creative_brief === null) {
      artRequest.creative_brief = {
        type: null,
        expedite_description: '',
      };
    }

    const customFields = this.customFieldsData;

    const filteredList = customFields.filter((item) => (item.subselection === this.artRequestData.creative_brief.type));

    const faceList = this.productionInfo.faceList;
    const info = this.productionInfo.productionInfo;
    const invIdList = this.productionInfo.invIdList;
    const prod = this.productionInfo.production;


    if (artRequest.ae === null) {
      artRequest.ae = {
        user_userid: '',
        user_username: '',
      };
    }

    if (artRequest.reqBriefCustomFields !== '') {
      artRequest.reqBriefCustomFields = JSON.parse(artRequest.reqBriefCustomFields);
    } else {
      artRequest.reqBriefCustomFields = {};
    }

    let isActive = false;
    if (artRequest.creative_brief.expedite_description !== '') {
      isActive = true;
    }

    artRequest.creative_brief.expedite_date = this.$dateFns.parse(artRequest.creative_brief.expedite_date, 'yyyy-MM-dd', new Date());

    for (let i = 0; i < filteredList.length; i++) {
      if (filteredList[i].selectionMeta1 === 'Date') {
        artRequest.reqBriefCustomFields[filteredList[i].selectionValue] = new Date(artRequest.reqBriefCustomFields[filteredList[i].selectionValue]);
      }
    }

    const imageViews = {
      small: 'Compact',
      large: 'Expanded',
      none: 'Hide Images',
    };

    return {
      artRequest: artRequest,
      customFields: customFields,
      files: this.attachments,
      comment: '',
      isAddingFile: false,
      expediteIsActive: isActive,
      info: info,
      prod: prod,
      invIdList: invIdList,
      faceList: faceList,
      filteredBriefs: filteredList,
      hasExpediteAccess: this.user.hasExpediteAccess,
      reqBrief: artRequest.reqBrief,
      imageViewOptions: imageViews,
      imageView: 'small',
      isSaved: true,
      invalidBriefFields: [],
    };
  },
  watch: {
    artRequest:  {
      handler: function(newValue) {
        this.updateArtRequest(newValue);
      },
      deep: true,
    },
    'reqBrief'(newValue) {
      this.logArtRequestChanges(newValue, this.artRequest.reqBrief, 'reqBrief', 'Task Description');
    },
    'artRequest.reqStatus'(newValue, oldValue) {
      if ((newValue !== oldValue) &&
                (this.artRequest.creative_brief.creative_date !== '' && this.artRequest.creative_brief.creative_date !== undefined)) {
        toast('notice', '\'Creative Deadline\' already set.');
      }
      this.addComment(`${oldValue} to ${newValue}`);

      this.appendItem({ update: 'reqStatus', oldValue, newValue });

      if (newValue.match('^Active') &&
                (this.artRequest.creative_brief.creative_date === '' || this.artRequest.creative_brief.creative_date === undefined)) {
        const requestObject = {
          reqId: this.artRequest.reqId,
          reqStartDate: this.artRequest.reqStartDate,
          reqStatus: this.artRequest.reqStatus,
          isSet: true,
        };
        this.updateCreativeBrief(requestObject);
      }
    },
    'artRequest.reqBrief'(newValue, oldValue) {
      this.appendItem({ update: 'reqBrief', oldValue, newValue });
    },
    'artRequest.reqStartDate'(newValue, oldValue) {
      // eslint-disable-next-line no-param-reassign
      oldValue = oldValue.toISOString().split('T')[0];
      // eslint-disable-next-line no-param-reassign
      newValue = newValue.toISOString().split('T')[0];
      this.appendItem({ update: 'reqStartDate', oldValue, newValue });
    },
    'artRequest.reqDueDate'(newValue, oldValue) {
      // eslint-disable-next-line no-param-reassign
      oldValue = oldValue.toISOString().split('T')[0];
      // eslint-disable-next-line no-param-reassign
      newValue = newValue.toISOString().split('T')[0];
      this.appendItem({ update: 'reqDueDate', oldValue, newValue });
    },
    'artRequest.reqInitialReviewDate'(newValue, oldValue) {
      // eslint-disable-next-line no-param-reassign
      oldValue = oldValue.toISOString().split('T')[0];
      // eslint-disable-next-line no-param-reassign
      newValue = newValue.toISOString().split('T')[0];
      this.appendItem({ update: 'reqInitialReviewDate', oldValue, newValue });
    },
    'artRequest.reqAssignedTo'(newValue, oldValue) {
      if (! newValue) {
        // eslint-disable-next-line no-param-reassign
        newValue = 'Unassigned';
      }
      if (! oldValue) {
        // eslint-disable-next-line no-param-reassign
        oldValue = 'Unassigned';
      }

      this.appendItem({ update: 'reqAssignedTo', oldValue, newValue });
    },
    'artRequest.creative_brief.type'(newValue) {
      let customList = this.customFields;

      customList = customList.filter((item) => (item.subselection === newValue));
      this.filteredBriefs = customList;
      const newList = this.filteredBriefs;
      const creativeBriefObject = {
        type: newValue,
      };
      const requestObject = {
        reqId: this.artRequest.reqId,
        creative_brief: creativeBriefObject,
      };
      this.updateCreativeBrief(requestObject);

      return newList;
    },
    'artRequest.creative_brief.expedite_date'(newValue) {
      const creativeBriefObject = {
        expedite_date: newValue,
        expedite_description: this.artRequest.creative_brief.expedite_description,
      };
      const requestObject = {
        reqId: this.artRequest.reqId,
        reqStartDate: this.artRequest.reqStartDate,
        creative_brief: creativeBriefObject,
      };
      this.expediteIsActive = newValue;
      this.artRequest.creative_brief.expedite_description = null;

      this.updateCreativeBrief(requestObject);
    },
    'artRequest.creative_brief.expedite_description'(newValue) {
      const creativeBriefObject = {
        expedite_date: this.artRequest.creative_brief.expedite_date,
        expedite_description: newValue,
      };
      const requestObject = {
        reqId: this.artRequest.reqId,
        reqStartDate: this.artRequest.reqStartDate,
        creative_brief: creativeBriefObject,
      };
      this.updateCreativeBrief(requestObject);
    },
  },
  mounted: function() {
    localStorage.setItem('changeLog', JSON.stringify([]));
    this.updateArtRequest = this.$lodash.debounce(function(artRequest) {
      const url = '/artRequests/' + artRequest.reqId;
      axios.post(url, artRequest)
        .then(response => {
          this.isSaved = true;
          this.artRequest.reqDepartment = response.data.reqDepartment;
          this.invalidBriefFields = [];

          if (this.artRequest.reqStartDate.toISOString().split('T')[0] !== response.data.reqStartDate) {
            const responseStartDate = this.$dateFns.parse(response.data.reqStartDate, 'yyyy-MM-dd', new Date());
            this.artRequest.reqStartDate = responseStartDate;
          }
          if (typeof response.data.emailArray !== 'undefined') {
            this.parseEmailArray(response.data.emailArray);
          }
          console.log(response.data.reqId);
        })
        .catch(error => {
          this.isSaved = false;

          this.invalidBriefFields = Object.keys(error.response.data.errors)
            .map((field)=>field.split('.').toSpliced(0,1).join('.'));

          toast('warning', error.response.data.message);
          console.log('error updating artRequest:', error.response);
        });
    }, 500);
    this.updateCreativeBrief = this.$lodash.debounce(function(artRequest) {
      const url = '/creativeBrief/' + artRequest.reqId;
      axios.post(url, artRequest)
        .then(response => {
          if (artRequest.isSet === true) {
            this.artRequest.creative_brief.creative_date = response.data.data.creative_date;
          }
        })
        .catch(error => {
          console.log(error.response.data.message);
          toast('notice', error.response.data.message);
        });
    }, 500);
    this.logArtRequestChanges = this.$lodash.debounce(function(newValue, oldValue, changedField, displayName) {
      this.artRequest[changedField] = newValue;
      this.addComment(`${displayName} changed from ${oldValue} to ${newValue}`);
    }, 500);
  },
  methods: {
    getItem: function(key = 'changeLog') {return JSON.parse(localStorage.getItem(key));},

    setItem: function(key = 'changeLog', item) {localStorage.setItem(key, JSON.stringify(item));},

    appendItem: function(item) {
      const data = this.getItem();
      if (Array.isArray(data)) {
        data.push(item);
        this.setItem('changeLog', data);
      }
    },
    addComment: function(changelog) {
      let commentValue = this.comment.trim();

      if (changelog) {
        commentValue = changelog.trim();
      }

      if (commentValue.length === 0) {
        return;
      }
      const timezone = new Date().toLocaleTimeString('en-us',{ timeZoneName:'short' }).split(' ')[2];
      const dateString = this.$moment().format('(YYYY-MM-DD hh:mm A') + ` (${timezone}))`;
      const comment = this.user.user_userid + dateString + ':\n' + commentValue + '\n\n';
      this.artRequest.reqHistory = comment + this.artRequest.reqHistory;
      this.comment = '';
    },
    isValidField: function(field) {
      return this.invalidBriefFields.includes(field);
    },
    parseEmailArray: function(emailJson) {
      if (emailJson.myAction === 'email') {
        const emailTo = emailJson.emailTo;
        const advertiserName = this.artRequest.reqAdvertiser.split('|')[0];
        const advertiserId = this.artRequest.reqAdvertiser.split('|')[1];
        const params = [];
        params['to']=emailTo;
        params['subject']= 'Art for '+ advertiserName +' ready.';
        const tempbody = decodeURIComponent(emailJson.emailBody);
        params['body'] = tempbody.replace(/\+/g,' ');
        params['title'] = 'Send Art Proof';
        params['subjectPreFill'] = params['subject'];
        params['messagePreFill'] = params['body'];
        params['recipientPreFill'] = params['to'];
        params['advertiserId'] = advertiserId;
        console.log('attempting post');
        this.postToUrl('/util/send/', params, 'post');
      }
      if (emailJson.myAction === 'no_email') {
        toast('notice', 'No Attachments to Send');
      }
      return true;
    },
    postToUrl: function(path, params, method = 'post') {
      // The rest of this code assumes you are not using a library.
      // It can be made less wordy if you use one.
      const form = document.createElement('form');
      form.setAttribute('method', method);
      form.setAttribute('action', path);
      form.setAttribute('target', 'extra');

      for (const key in params) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);

        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);    // Not entirely sure if this is necessary
      form.submit();
    },
    deleteAttachment: function(file, index) {
      const url = '/artRequests/attachments/' + file.reqa_id;
      axios.delete(url).then(() => {
        delete this.files[index];
      });
    },
    updateAttachment: function(file) {
      const url = '/artRequests/attachments/' + file.reqa_id;
      axios.post(url, file)
        .then(response => {
          if (response.data.exceedsMaxFileSize === true) {
            toast('error', 'File ' + file.reqa_filename + ' is over the max allowed size of ' + response.data.maxFileSize + 'MB for Digital Use. Recommended size is 1MB or less.');
          }
        })
        .catch(response => {
          console.log('error updating attachment:', response);
        });
    },
    thumbsrc: function(file) {
      const timestamp = file.reqa_created_date.replace(/\D/g, '');
      return '/apx/digitalManagement/view/' + timestamp + '/' + this.imageView + '/' + file.reqa_id + '/' + file.reqa_filename.substr(0, file.reqa_filename.lastIndexOf('.')) + '.jpg';
    },
    fileUploadHandler: function(file) {
      if (Object.keys(this.files).length === 0) {
        this.files = {
          [file.id]: {
            reqa_id: file.id,
            reqa_reqId: this.artRequest.reqId,
            reqa_attachment_type: file.type,
            reqa_filename: file.name,
            reqa_created_date: this.$moment().format('YYYY-MM-DD'),
            image: file.image,
          },
        };
      } else {
        const fileObject = this.files;
        const newFileObject = {
          [file.id]: {
            reqa_id: file.id,
            reqa_reqId: this.artRequest.reqId,
            reqa_attachment_type: file.type,
            reqa_filename: file.name,
            reqa_created_date: this.$moment().format('YYYY-MM-DD'),
            image: file.image,
          },
        };
        this.files = Object.assign(fileObject, newFileObject);
      }
    },
  },
};
</script>

<style scoped>
    select {
        background-color: white;
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(-500px);
    }
</style>
