<template>
  <span class="text-sm">
    <span class="py-1 px-3 rounded-md bg-main text-white">{{ pageName }}</span>
    <span class="ml-3 text-gray-500"><strong>Proposal #{{ proposalNumber }}:</strong> {{ proposalName }}</span>
  </span>
</template>

<script>
export default {
  props: {
    pageName: String,
    proposal: Object,
  },
  computed: {
    proposalNumber: function() {
      let number = this.proposal.propId;
      if (this.proposal.propVersion > 0) {
        number += '-' + this.proposal.propVersion;
      }
      return number;
    },
    proposalName: function() {
      return (this.proposal.propName.length > 0) ? this.proposal.propName : this.proposal.propBudgetRecord;
    },
  },
};
</script>

<style scoped>
</style>
