type ResolvedCallback<T> = (value: T) => void;

interface Catchable<T> {
  catch(cb: ResolvedCallback<T>): void;
}

export class ResolvablePromise<TValue, TErr = unknown> {
  public promise: Promise<TValue>;
  #resolve!: ResolvedCallback<TValue>;
  #reject!: ResolvedCallback<TErr>;
  #catchCallback?: ResolvedCallback<TErr>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.#resolve = resolve;
      this.#reject = reject;
    });
  }

  public resolve(value: TValue): void {
    this.#resolve(value);
  }

  public reject(value: TErr): void {
    this.#reject(value);
  }

  public then(cb: ResolvedCallback<TValue>): Catchable<TErr> {
    this.promise
      .then(result => cb(result))
      .catch(err => {
        if (typeof this.#catchCallback === 'function') {
          this.#catchCallback(err);
        }
      });

    return this;
  }

  public awaitResult(): Promise<TValue> {
    return this.promise;
  }

  public catch(cb: ResolvedCallback<TErr>): void {
    this.#catchCallback = cb;
  }
}
