<!-- eslint-disable camelcase -->
<script setup lang="ts">
import { ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import { useDigitalVendorEndpoint } from '../composables/useDigitalVendorEndpoint';
import { DigitalVendorEndpointStore as store } from '../stores/DigitalVendorEndpointStore';

const { saveOrUpdateModel } = useDigitalVendorEndpoint();
const canSubmit = ref(false);
const isLoading = ref(false);
const hasError = ref(false);
const submitSuccess = ref(false);
const isEditingExisting = ref(false);
const vendor = ref(store.getVendorByActiveEndpoint());

const submitText = ref('');
const successText = ref(store.activeEndpoint.id ? 'updated' : 'created');
const errorText = ref('');
const dropdownPlaceholder = ref(store.activeEndpoint.type ?? 'Select type');

watch([
  () => store.activeEndpoint.id,
  () => store.activeEndpoint.type,
  () => store.activeEndpoint.base_url,
  () => store.activeEndpoint.digital_vendor_id,
], ([id, type, base_url, digital_vendor_id]) => {
  canSubmit.value = !!type && !!base_url && !!digital_vendor_id;
  isEditingExisting.value = !!id;
  submitText.value = id ? 'Update' : 'Create';
  errorText.value = id ? 'updating' : 'creating';
}, { immediate: true });


const handleSubmit = async () => {
  isLoading.value = true;
  const result = await saveOrUpdateModel(store.activeEndpoint);
  if (result) {
    if (isEditingExisting.value) {
      store.updateEndpoint(result);
    } else {
      store.addEndpointToList(result);
    }
    store.setActiveEndpoint(result);
    isLoading.value = false;
    hasError.value = false;
    submitSuccess.value = true;
    setTimeout(() => {
      submitSuccess.value = false;
      successText.value = 'updated';
    }, 3000);
  } else {
    hasError.value = true;
    isLoading.value = false;
  }
};
</script>

<template>
  <template v-if="store.activeEndpoint">
    <form>
      <div class="grid grid-cols-2 auto-rows-auto grid-flow-row-dense gap-x-4 gap-y-8">
        <div class="form-element">
          <label>Digital vendor name</label>
          <InputText v-model="vendor.display_name" disabled />
        </div>
        <div class="form-element">
          <label>Type</label>
          <Dropdown
            v-model="store.activeEndpoint.type"
            :options="store.validEndpointTypes"
            option-label="name"
            option-value="value"
            :placeholder="dropdownPlaceholder"
            class="w-full"
          />
        </div>
        <div class="form-element-full">
          <label class="uppercase">url</label>
          <InputText v-model="store.activeEndpoint.base_url" />
        </div>
        <div class="form-element-full">
          <label>Organization</label>
          <InputText v-model="store.activeEndpoint.organization" />
        </div>
        <div class="form-element">
          <label>Client ID</label>
          <InputText v-model="store.activeEndpoint.client_id" />
        </div>
        <div class="form-element">
          <label>Client secret</label>
          <Password
            v-model="store.activeEndpoint.client_secret"
            input-class="w-full"
            :feedback="false"
            toggle-mask
          />
        </div>
        <div class="form-element">
          <label>Username</label>
          <InputText v-model="store.activeEndpoint.username" />
        </div>
        <div class="form-element">
          <label>Password</label>
          <Password
            v-model="store.activeEndpoint.password"
            input-class="w-full"
            :feedback="false"
            toggle-mask
          />
        </div>
        <div class="form-element">
          <label><span class="uppercase">api</span> key</label>
          <InputText v-model="store.activeEndpoint.api_key" />
        </div>
        <div class="form-element">
          <label>Swap key</label>
          <InputText v-model="store.activeEndpoint.swap_key" />
        </div>
        <div class="flex flex-row gap-2 col-span-2">
          <div>
            <Button :disabled="!canSubmit" style="max-height: 50px" @click="handleSubmit()">
              <template v-if="!isLoading">
                {{ submitText }}
              </template>
              <template v-else>
                <ProgressSpinner
                  style="width: 40px; height: 40px"
                  stroke-width="2"
                  animation-duration=".5s"
                  :pt="{
                    circle: {
                      style: {
                        stroke: '#FFFFFF !important'
                      }
                    }
                  }"
                />
              </template>
            </Button>
          </div>
          <Message v-if="submitSuccess" severity="success">
            Endpoint successfully {{ successText }}
          </Message>
          <Message v-if="hasError" severity="error">
            Error {{ errorText }} endpoint
          </Message>
        </div>
      </div>
    </form>
  </template>
</template>
