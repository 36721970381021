<script setup lang="ts">

// Imports
// -----------------------------------------------------------------------------------------------------------
import { ref } from 'vue';
import axios from 'axios';
import { useCrossFrameMessaging } from '@apparatix/composables';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

// -----------------------------------------------------------------------------------------------------------

// Props and variables
// -----------------------------------------------------------------------------------------------------------

interface MyComponentProps {
  username: string,
  apxcode: string,
  hasCampaignPipeline: boolean,
  hasMyCampaignPipeline: boolean,
  hasAllAccounts: boolean,
}

const props = defineProps<MyComponentProps>();

const TOAST_LIFE = 3000;
const { sendMessage } = useCrossFrameMessaging(true);
const isEditing = ref(false);
const campaignStartName = ref('');
const toast = useToast();
// -----------------------------------------------------------------------------------------------------------


// Functions
// -----------------------------------------------------------------------------------------------------------

const slideOut = async () => {
  if (top.window.length >= 3 && typeof top.window[2].slideout === 'function') {
    top.window[2].slideout();
  }
};

const toggleEdit = () => {
  isEditing.value = !isEditing.value;

  if (!isEditing.value) {
    campaignStartName.value = '';
  }
};

const submit = () => {
  startCampaign();
  toggleEdit();
};

const startCampaign = async () => {

  // Add new campaign using ajaxAddCampaignRecord (converted from proposal.html)
  try {

    // Create the campaign
    const thisCampaignStartName = campaignStartName.value;
    if (thisCampaignStartName === '') {
      showToast('error', 'Error', 'Campaign Name cannot be blank.');
      return;

    }
    const response = await axios.get('/util/ajax/ajaxAddCampaignRecord.php?list=' + props.apxcode + '|' + encodeURIComponent(thisCampaignStartName) + '|||' + encodeURIComponent('4 week'));

    if (response.status === 200) {
      await showToast('success', 'Success', 'Campaign Created Successfully!');
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    else {
      showToast('error', 'Error', 'Campaign not created.');
      return;
    }

    const xmlResponse = response.data;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

    const resultElement = xmlDoc.getElementsByTagName('result')[0];
    const resultValue = resultElement.textContent;

    const urlParameters = '?my='+encodeURIComponent(props.hasCampaignPipeline)+'&fromLeads=1&apxCode='+encodeURIComponent(props.apxcode)+'&campaignStartName='+encodeURIComponent(thisCampaignStartName)+'&campaignId='+encodeURIComponent(resultValue);

    await slideOut();

    if (! props.hasMyCampaignPipeline) {
      await sendMessage({
        type: 'navigate',
        data: {
          parentPage: 'Accounts',
          childPage: 'Campaign Pipeline',
          appendToUrl: urlParameters,
        },
      });
    } else {
      await sendMessage({
        type: 'navigate',
        data: {
          parentPage: 'Accounts',
          childPage: 'My Campaign Pipeline',
          appendToUrl: urlParameters,
        },
      });
    }
    return;
  } catch (e) {
    showToast('error', 'Error', e + ' \nDuplicate Campaign Name.');
    return;
  }



};


const goToAccount = () => {

  slideOut();

  // Redirect user to account page
  const urlParameters = '?fromLeads=1&apxCode=' + encodeURIComponent(props.apxcode);

  sendMessage({
    type: 'navigate',
    data: {
      parentPage: 'Accounts',
      childPage: 'All Accounts',
      appendToUrl: urlParameters,
    },
  });

};

const showToast = async (severity, summary, message) => {
  toast.add({
    severity: severity, summary: summary, detail: message, life: TOAST_LIFE,
  });
};
// -----------------------------------------------------------------------------------------------------------
</script>


<template>
  <div>
    <div class="header-container">
      <div class="top-icons-container">
        <i class="pi pi-circle-fill" />
        <i class="pi pi-check overlay-icon" />
      </div>
      <div class="text-container">
        <h1 class="text-line-top">
          Lead converted to Prospect!
        </h1>
        <p class="text-line-bottom">
          What would you like to do next?
        </p>
      </div>
    </div>

    <div>
      <div class="all-actions-container">
        <div v-if="props.hasCampaignPipeline || props.hasMyCampaignPipeline" class="new-campaign-container">
          <div v-if="!isEditing" class="action-container" @click="toggleEdit">
            <i class="pi pi-dollar left-action-icon" style="font-size: 1rem" /> <i class="pi pi-circle" />
            <h1 class="action-text">
              Start a Campaign for the Account
            </h1>
            <span class="pi pi-chevron-right" />
          </div>
          <div v-else class="editing-container">
            <div class="input-container">
              <InputText
                v-model="campaignStartName"
                type="text"
                placeholder="Type a Campaign name for the new Account"
                class="p-mb-2 full-width-input"
              />
            </div>
            <div class="buttons-container">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-secondary"
                @click="toggleEdit"
              />
              <Button
                label="Submit"
                icon="pi pi-check"
                class="p-button p-mr-2"
                @click="submit"
              />
            </div>
          </div>
        </div>


        <div v-if="props.hasAllAccounts" class="action-container" @click="goToAccount">
          <i class="pi pi-building left-action-icon" />
          <h1 class="action-text" style="margin-left: 35px;">
            View the Account profile
          </h1>
          <span class="pi pi-chevron-right" />
        </div>


        <div class="action-container" @click="slideOut">
          <i class="pi pi-user left-action-icon" /> <i class="pi pi-circle" />
          <h1 class="action-text">
            Return to your Leads
          </h1>
          <span class="pi pi-chevron-right" />
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>


<style scoped>
    .all-actions-container {
        width: 500px;
    }

    .action-container {
        cursor: pointer;
        padding: 10px;
        text-align: center;
        width: 500px;
        height: 65px;
        margin: 20px auto;
        border-radius: 4px;
        user-select: none;
        border: 1px solid lightgray;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .action-container:hover {
        background-color: lightgray;
    }

    .action-text {
        flex: 1;
        text-align: left;
        margin-left: 10px;
    }

    .action-container .pi-circle {
        font-size: 1.5rem;
        transform: translate(-85%, -0%);
        color: var(--primary-blue);
    }

    .action-container .pi-dollar {
        color: var(--primary-blue);
    }

    .action-container .pi-building {
        font-size: 1.35rem;
        color: var(--primary-blue);
        transform: translateX(-3px);
    }

    .action-container .pi-user {
        color: var(--primary-blue);
    }

    .action-container .pi-chevron-right {
        color: var(--primary-blue);
    }

    .editing-container {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
        border-radius: 3px;
        overflow: hidden;
    }

    .input-container {
        background-color: white;
        padding: 10px;
    }

    .buttons-container {
        display: flex;
        justify-content: flex-end;
        background-color: #f0f0f0;
        padding: 10px;
    }



    .buttons-container .p-button-secondary:hover {
        background-color: lightgray;
    }

    .buttons-container .p-button {
        margin-left: 10px;
    }

    .buttons-container .p-button:hover {
        background-color: #0056b3;
    }

    .buttons-container .p-button:last-child {
        margin-right: 0;
    }

    :deep(.p-button) {
        gap: 8px;
        height: 36px;
        padding: 0 12px;
        font-size: 14px;
        background: var(--primary-blue);
        color: var(--white);
        border-radius: 8px;
        box-shadow: 0 4px 8px -4px var(--black-15);
        text-transform: capitalize;

        &.p-button-secondary {
        background: var(--white);
        border: 1px solid var(--black-16);
        color: var(--black-80);
        }
    }


    .top-icons-container .pi-check {
        font-size: 1.3em;
        color: #2b9c74;
    }

    .top-icons-container .pi-circle-fill {
        font-size: 2.5em;
        color: #c0f7e4;
    }


    .overlay-icon {
        transform: translate(-140%, -45%);
    }

    .left-action-icon {
        margin-left: 20px;
    }

    .header-container {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
    }
    .text-container {
        display: flex;
        flex-direction: column;
    }
    .text-line-top {
        font-size: 1.15em;
        font-weight: bold;

    }
    .text-line-bottom {
        font-size: 0.9em;
        color: #6c757d;
    }

    .full-width-input {
        width: 100%;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        margin: 0;
    }
</style>
