import { onMounted, onUnmounted, ref } from 'vue';
import { defineStore } from 'pinia';
import { useThrottle } from './useThrottle';

export type ScreenSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const sizes: ScreenSize[] = ['sm', 'md', 'lg', 'xl', '2xl'];

const getScreenSize = (viewportWidth: number): ScreenSize => {
  if (viewportWidth <= 640) {
    return 'sm';
  }

  if (viewportWidth > 640 && viewportWidth <= 768) {
    return 'md';
  }

  if (viewportWidth > 768 && viewportWidth <= 1024) {
    return 'lg';
  }

  if (viewportWidth > 1024 && viewportWidth <= 1280) {
    return 'xl';
  }

  return '2xl';
};

export const useResponsive = defineStore('responsive-store', () => {
  const screenSize = ref<ScreenSize>(getScreenSize(window.innerWidth));

  const onWindowResize = () => {
    screenSize.value = getScreenSize(window.innerWidth);
  };
  const throttledResizeHandler = useThrottle(onWindowResize, 250);

  const compareScreenSize = (testSize: ScreenSize, checkSmaller: boolean, includeEqual: boolean): boolean => {
    const testIndex = sizes.findIndex(size => size === testSize);
    const actualIndex = sizes.findIndex(size => size === screenSize.value);

    if (checkSmaller && includeEqual) {
      return testIndex >= actualIndex;
    }

    if (checkSmaller && !includeEqual) {
      return testIndex > actualIndex;
    }

    if (!checkSmaller && includeEqual) {
      return testIndex <= actualIndex;
    }

    return testIndex < actualIndex;
  };

  const isScreenBelow = (size: ScreenSize) => compareScreenSize(size, true, false);
  const isScreenAtOrBelow = (size: ScreenSize) => compareScreenSize(size, true, true);
  const isScreenAbove = (size: ScreenSize) => compareScreenSize(size, false, false);
  const isScreenAtOrAbove = (size: ScreenSize) => compareScreenSize(size, false, true);

  const isMobile = isScreenAtOrBelow('sm');

  onMounted(() => {
    screenSize.value = getScreenSize(window.innerWidth);

    window.addEventListener('resize', throttledResizeHandler);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', throttledResizeHandler);
  });

  return {
    screenSize,
    isScreenBelow,
    isScreenAtOrBelow,
    isScreenAbove,
    isScreenAtOrAbove,
    isMobile,
  };
});
