import { reactive } from 'vue';
import {
  AvailableViews,
  CachedFrame,
  SidebarMenuItem,
  TopLevelNavigation,
  TopLevelNavigationItem,
  User,
} from '@apparatix/types';

interface AppState {
  currentUser: User;
  realUser: User;
  companyLogo: string;
  isImpersonating: boolean;
  defaultPage: string;
  navigationItems: TopLevelNavigation;
  overflowNavigation: TopLevelNavigation;
  activePage: TopLevelNavigationItem;
  activeChildren: Array<SidebarMenuItem>;
  activeSubPage: SidebarMenuItem;
  loadedFrames: Array<CachedFrame>;
  rootView: AvailableViews;
  activeFrameUrl: string;
  isFrameLoading: boolean;
  shouldShowDashboard: boolean;
  showPageNotImplemented: boolean;
  shouldRefreshDashboard: boolean;
  shouldShowDashboardNotificationToast: boolean;
  setActiveChildren: (newChildren: Array<SidebarMenuItem>) => void;
  setActivePage: (newPage: TopLevelNavigationItem) => void;
  setActiveSubPage: (newPage: SidebarMenuItem) => void;
  setCurrentUser: (user: User) => void;
  setActiveUrl: (url: string, uuid: string) => void;
  getPagesBySystemId: (id: number) => [TopLevelNavigationItem, SidebarMenuItem];
  setActivePageByTitle: (title: string) => void;
  refreshDashboard: () => void;
  refreshCurrentPage: () => void;
  showDashboardNotificationToast: () => void;
  getHelpForCurrentPageUrl: () => string;
}

export const AppStore: AppState = reactive<AppState>({
  currentUser: {} as User,
  realUser: {} as User,
  companyLogo: '',
  defaultPage: 'Dashboard',
  isImpersonating: false,
  navigationItems: [] as TopLevelNavigation,
  overflowNavigation: [] as TopLevelNavigation,
  activePage: {} as TopLevelNavigationItem,
  activeChildren: [],
  activeSubPage: {} as SidebarMenuItem,
  loadedFrames: [] as Array<CachedFrame>,
  rootView: 'legacy',
  activeFrameUrl: '',
  isFrameLoading: false,
  shouldShowDashboard: false,
  showPageNotImplemented: false,
  shouldRefreshDashboard: false,
  shouldShowDashboardNotificationToast: false,
  setActiveChildren(newChildren: Array<SidebarMenuItem>): void {
    this.activeChildren = newChildren;
  },
  setActivePage(newPage: TopLevelNavigationItem): void {
    this.activePage = newPage;

    // Hide the new notice toast automatically when navigating to the dashboard
    if (newPage.label === 'Dashboard' && this.shouldShowDashboardNotificationToast) {
      this.shouldShowDashboardNotificationToast = false;
    }

    // Remember the last sidebar page visited in this category
    if (newPage.lastChildUuid) {
      const child = newPage.children.find(c => c.uuid === newPage.lastChildUuid);
      child?.command();
    } else {
      // Open the category home page or default home page
      const defaultPage = getDefaultPage(newPage.children);
      defaultPage?.command();
    }

    if (newPage.label.toLocaleLowerCase() === 'dashboard' && this.shouldRefreshDashboard) {
      this.shouldRefreshDashboard = false;
      refreshIframe('/iframe-wrapper/landing/dashboard');
    }

    this.setActiveChildren(newPage.children);
  },
  setActiveSubPage(newPage: SidebarMenuItem): void {
    if (newPage.rootView) {
      this.rootView = newPage.rootView;
    } else {
      this.rootView = 'legacy';
    }

    if (this.activeSubPage.uuid === newPage.uuid) {
      this.refreshCurrentPage();
      return;
    }
    this.activeSubPage = newPage;

    if (this.rootView === 'legacy') {
      this.setActiveUrl(newPage.url, newPage.uuid);
    }

    const parentIdx = this.navigationItems.findIndex(item => item.uuid === newPage.parentUuid);
    if (parentIdx === -1) return;

    this.navigationItems[parentIdx].lastChildUuid = newPage.uuid;
  },
  setCurrentUser(user: User): void {
    this.currentUser = user;
  },
  setActiveUrl(url: string, uuid: string): void {
    if (this.activeFrameUrl === url) return;
    this.activeFrameUrl = url;
    this.isFrameLoading = true;

    if (url === '') {
      this.isFrameLoading = false;
      return;
    }

    const urlIndex = this.loadedFrames.findIndex(item => item.url === url);
    if (urlIndex === -1) {
      this.loadedFrames.push({
        url: url,
        timestamp: new Date(),
        isExpired: false,
        sidebarUuid: uuid,
      });
    } else {
      this.loadedFrames[urlIndex].timestamp = new Date();
      this.loadedFrames[urlIndex].isExpired = false;
      this.isFrameLoading = false;
    }
  },
  getPagesBySystemId(id: number): [TopLevelNavigationItem, SidebarMenuItem] {
    let safeId: number;
    try {
      if (typeof id !== 'number') {
        safeId = parseInt(id, 10);
      } else {
        safeId = id;
      }
    } catch {
      console.warn('Invalid value provided to page lookup. Id must be a number, or a string that can be parsed into a number');
      return [null, null];
    }

    let parent: TopLevelNavigationItem = null;
    let child: SidebarMenuItem = null;

    this.navigationItems.forEach(parentItem => {
      const childItem = parentItem.children?.find(_child => _child.systemId === safeId);

      if (childItem) {
        parent = parentItem;
        child = childItem;
        return;
      }
    });

    return [parent, child];
  },
  setActivePageByTitle(title: string): void {
    if (title.toLowerCase() === this.activePage.label.toLowerCase()) {
      return;
    }

    const page = this.navigationItems.find(item => item.label.toLowerCase() === title.toLowerCase());
    if (page) {
      this.setActivePage(page);
    }
  },
  refreshDashboard() {
    refreshIframe('/iframe-wrapper/landing/dashboard');
  },
  refreshCurrentPage() {
    if (this.rootView === 'legacy') {
      this.isFrameLoading = true;
    }
    refreshIframe(this.activeSubPage.url);
  },
  showDashboardNotificationToast() {
    if (this.activePage.label === 'Dashboard') return;

    this.shouldShowDashboardNotificationToast = true;
  },
  getHelpForCurrentPageUrl(): string {
    if (!this.activeSubPage) return null;
    return `/help/wwh_core/${this.activeSubPage.label}/${this.activeSubPage.systemId}`;
  },
});

const getDefaultPage = (sidebarItems: Array<SidebarMenuItem>): SidebarMenuItem => {
  if (!sidebarItems.length) return null;

  const explicitHomePage = sidebarItems.find(item => item.isHomePage);
  if (explicitHomePage) {
    return explicitHomePage;
  }

  const defaultHomePage = sidebarItems.find(item => item.isCategoryHome);
  if (defaultHomePage) {
    return defaultHomePage;
  }

  return sidebarItems[0];
};

const refreshIframe = (src: string) => {
  const iframe = document.querySelector(`iframe[src="${src}"]`) as HTMLIFrameElement;
  iframe?.contentWindow.location.replace(src);
};
