<template>
  <div class="h-screen">
    <div class="flex flex-col h-full grow">
      <div class="flex flex-row">
        <div class="grid grid-cols-12 basis-full">
          <div class="col-span-12">
            <div class="set-z-index grid grid-cols-12 px-8 pt-8 pb-4 rounded-t-lg bg-stuart-purple font-inter">
              <div class="col-span-1 font-bold text-white text-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-6 h-6 text-stuart-gray-purple"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
              </div>

              <div class="col-span-10">
                <div class="grid grid-cols-12">
                  <h1 class="col-span-6 pr-2 text-white text-center text-lg font-semibold">
                    Email Schedule List
                  </h1>
                  <div class="col-span-6 border-0 px-4 py-2 bg-stuart-darker-purple w-fit h-fit rounded-full text-stuart-gray-purple font-medium text-xs">
                    {{ reportName }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <button class="hover:bg-stuart-gray-purple rounded-full text-white" @click="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.0"
                    stroke="currentColor"
                    class="w-6 h-6 text--gray-300"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row grow min-h-0">
        <div class="h-full overflow-y-auto scrollbar-hide basis-full">
          <div class="grid grid-cols-12">
            <p class="col-span-12 px-8 pb-4 bg-stuart-purple text-xs font-inter text-stuart-gray-purple leading-5 font-normal">
              Manage automatic email deliveries for your recipients.
            </p>

            <div class="col-span-12 px-8 pt-8">
              <SearchInput v-model="search" />
            </div>

            <div v-if="filteredScheduleList.length > 0" class="col-span-12 px-8 pt-8 mb-20">
              <div class="grid grid-cols-12 border rounded-md border-gray-300">
                <div
                  v-for="(schedule, index) in filteredScheduleList"
                  :key="index"
                  :value="schedule"
                  class="col-span-12"
                >
                  <div class="grid grid-cols-12">
                    <button class="col-span-11" @click="openSchedule(schedule, false)">
                      <div class="col-span-12 px-4 pt-2 pb-1 text-sm text-left">
                        {{ schedule.name }}
                      </div>
                      <div class="col-span-12 px-4 pt-1 pb-2 text-gray-400 text-xs font-medium text-left">
                        {{ checkScheduleProperties(schedule) }}
                      </div>
                    </button>
                    <button :disabled="disabled" class="col-span-1" @click="deleteReport(schedule.id)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 inline-block align-text-bottom font-bold text-gray-400"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    </button>
                    <div v-if="index !== Object.keys(emailScheduleList).length - 1" class="col-span-12">
                      <div class="border-b border-gray-300" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="col-span-12 px-8 pt-8 text-center mb-20">
              <h3>No schedules yet. Click below to create your first scheduled report send.</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col h-min">
        <div class="flex flex-row">
          <div class="grid grid-cols-12 basis-full">
            <div class="col-span-12 fixed bg-white border-t border-gray-300 rounded-b-lg inset-x-0 bottom-0 px-8 py-4">
              <button type="button" class="rounded-md bg-stuart-purple w-full px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="swapComponent('create-email-schedule', {}, true)">
                Create Email Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from 'vue-search-input';

export default {
  components: {
    SearchInput,
  },
  props: [
    'swapComponent',
    'reportName',
    'data',
    'schedule',
  ],
  data: function() {
    window.scrollTo(0, 0);

    return {
      emailScheduleList: this.data,
      childData: {
        schedule: {},
        isCreate: true,
      },
      disabled: false,
      search: '',
    };
  },
  computed: {
    filteredScheduleList() {
      return this.emailScheduleList.filter(s => s.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
    },
  },
  methods: {
    checkScheduleProperties: function(schedule) {
      const frequency = JSON.parse(schedule.frequency);
      if (frequency.isOneTimeDelivery) {
        if (schedule.next_delivery_datetime === null) {

          return 'One time delivery is set but not scheduled';
        }

        if (schedule.end_date !== null) {
          const date = new Date(schedule.end_date);
          console.log(schedule.end_date);
          console.log(date);

          return 'One time delivery completed on ' + (date.getMonth()+1) + '/' + (date.getDate()) + '/' + date.getFullYear();
        }

        const date = new Date(schedule.next_delivery_datetime);

        return 'One time delivery on ' + (date.getMonth()+1) + '/' + (date.getDate()) + '/' + date.getFullYear();
      }

      let interval = frequency.repeatEvery.interval;
      let period = frequency.repeatEvery.period + 's';
      if (frequency.repeatEvery.interval === 1) {
        interval = '';
        period = frequency.repeatEvery.period;
      }

      let daysOfWeek = '';
      if (frequency.repeatEvery.period === 'week') {
        daysOfWeek = ' on ' + frequency.repeatEvery.daysOfWeek.join(', ');
      }

      let timeOfMonth = '';
      if (frequency.repeatEvery.period === 'month') {
        if (frequency.repeatEvery.isStartOfMonth) {
          timeOfMonth = ' at Start of Month';
        } else {
          timeOfMonth = ' at End of Month';
        }
      }

      let endDate = ' No end date';
      if (schedule.end_date !== null) {
        const date = new Date(schedule.end_date);
        endDate = ' Ends by ' + (date.getMonth()+1) + '/' + (date.getDate()+1) + '/' + date.getFullYear();
      }

      return 'Repeats every ' + interval + ' ' + period + timeOfMonth + daysOfWeek + ' ' + String.fromCharCode(183) + endDate;
    },
    clearSearch: function() {
      this.search = '';
    },
    closeModal: function() {
      window.parent.document.dispatchEvent(
        new CustomEvent('closeReportScheduleModalEvent', {}),
      );
    },
    deleteReport: function(id) {
      let index;
      for (let i = 0; i < this.emailScheduleList.length; i++) {
        if (this.emailScheduleList[i].id === id) {
          index = i;
          break;
        }
      }

      if (! confirm('Are you sure you want to delete this Email Schedule?')) {
        return;
      }
      this.disabled = true;
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 750);
      if (index > -1) {
        this.emailScheduleList.splice(index, 1);
      }
      axios.delete(`/report-schedules/delete-email-schedule/${id}`, {})
        .catch(error => {
          toast('warning', error.response.data.message);
        })
        .then(response => {
          toast('success', response.data.message);
        });
    },
    openSchedule: function(schedule, isCreate = true) {
      this.swapComponent('create-email-schedule', schedule, isCreate);
    },
  },
};
</script>

<style scoped>
.set-z-index {
    z-index: 51;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

</style>
