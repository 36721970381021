<template>
  <div v-cloak class="max-w-6xl mx-auto">
    <div class="grid grid-cols-12">
      <div class="col-span-12 px-6">
        <proposal-header
          page-name="Target Audience Inventory"
          :proposal="proposal"
        />
      </div>
      <div class="col-span-12 p-6">
        <h1 class="text-4xl font-medium max-h-20">
          {{ audienceName }}
        </h1>
      </div>
      <div v-show="proposalInventory.length == 0" class="col-span-12 col-span-12 h-3/5">
        <app-callout>
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-red-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </template>
          <h3 class="text-lg text-red-500 font-bold">
            Error
          </h3>
          <p>Geopath Measurements have not yet loaded. Try again in a few minutes.</p>
        </app-callout>
      </div>
      <div v-if="proposalInventory.length > 0" class="col-span-12 p-6">
        <app-table :table-data="proposalInventory" />
      </div>
      <proposal-footer
        :proposal="proposal"
        :logo_url="logo_url"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    audience: Object,
    logo_url: String,
    mapName: String,
    measurements: Array,
    proposal: Object,
  },
  data: function() {
    return {
      proposalSummary: null,
      proposalFaces: null,
      proposalInventory: [],
    };
  },
  computed: {
    audienceName: function() {
      const name = this.audience.name;
      const maxLength = 90;
      return (name.length > maxLength) ? name.substr(0, name.lastIndexOf(' ', maxLength)) + '...' : name;
    },
  },
  mounted: function() {
    const app = this;
    if (this.measurements.length === 0) {
      axios.get(`/proposal/geopath-measures/inventory/${this.proposal.propId}/${this.audience.id}/${this.mapName}`)
        .then((response) => {
          app.proposalInventory = response.data;
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    } else {
      const measurements = this.measurements;
      this.proposalInventory = measurements.map(face => face.filter(measure => measure.includeIn.includes('inventory')));
    }
  },
};
</script>

<style scoped>
</style>
