
import { createApp as prodCreateApp } from 'vue/dist/vue.esm-bundler.js';
import { createApp as devCreateApp, h } from 'vue';
import { createPinia } from 'pinia';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createInertiaApp } from '@inertiajs/vue3';
import type { App as IApp, Component, DefineComponent } from 'vue';
import { autoloadVueComponents } from 'virtual:vue-component-autoload';
import _ from 'lodash';
import axios from 'axios';
import * as dateFns from 'date-fns';
import moment from 'moment';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Badge from 'primevue/badge';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import { Switch } from '@headlessui/vue';
import Datepicker from 'vue3-datepicker';
import draggable from 'vuedraggable';
import SwapIcon from '@apparatix/icons/SwapIcon.vue';
import SearchInput from 'vue-search-input';
import 'vue-search-input/dist/styles.css';
import 'primeicons/primeicons.css';
import { ResolvablePromise } from '@apparatix/utilities/ResolvablePromise';
import { ZiggyVue } from 'ziggy-js';
import { Apparatix } from './theme/Apparatix';
import './theme/colors.css';

type AppCallback = (app: IApp) => void;
export interface IInit {
  beforeMount(callback: AppCallback): IBeforeMount;
  mount(): IMount;
}

interface IBeforeMount {
  mount(): IMount;
}

interface IMount {
  afterMount(callback: AppCallback): void;
}

export class AppFactory implements IInit, IBeforeMount, IMount {
  #app: IApp;
  #rootElement: Element | string;
  #appReady = new ResolvablePromise<boolean>();
  #internalSetupComplete = new ResolvablePromise<boolean>();
  #appMounted = new ResolvablePromise<boolean>;
  #readyToMount = Promise.all([this.#appReady.promise, this.#internalSetupComplete.promise]);
  #afterMountComplete = new ResolvablePromise<boolean>();

  private constructor(supportInertia: boolean = true, rootComponent?: Component) {
    // TODO: verify this isn't double including vue in the bundle
    const createApp = import.meta.env.DEV ? devCreateApp : prodCreateApp;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _self = this;

    if (supportInertia) {
      createInertiaApp({
        title: title => _self.#buildTitle(title),
        resolve: name => resolvePageComponent<DefineComponent>(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue') as any),
        setup({
          el, App, props, plugin,
        }) {
          _self.#app = createApp({ render: () => h(App, props) }).use(plugin);
          _self.#rootElement = el;
        },
      }).then(() => _self.#appReady.resolve(true));
    } else {
      this.#app = createApp(rootComponent ?? {});
      this.#rootElement = '#app';
      this.#appReady.resolve(true);
    }
  }

  public static init(supportInertia: boolean = true, rootComponent?: Component): IInit {
    // TODO: research whether singleton pattern here would be better
    // if(!this.#instance) {
    //   this.#instance = new AppFactory(supportInertia);
    // }

    // this.#instance.#appReady.then(() => {
    //   this.#instance.#internalBeforeMount();
    // });

    // return this.#instance;
    const instance = new AppFactory(supportInertia, rootComponent);
    instance.#appReady.then(() => instance.#internalBeforeMount());
    return instance;
  }

  public beforeMount(callback: (app: IApp) => void): IBeforeMount {
    if (typeof callback !== 'function') return this;
    this.#internalSetupComplete.then(() => callback(this.#app));
    return this;
  }

  public mount(): IMount {
    this.#readyToMount.then(() => {
      this.#app.mount(this.#rootElement);
      this.#appMounted.resolve(true);
    });

    return this;
  }

  public afterMount(callback: AppCallback): void {
    if (typeof callback !== 'function') return;
    this.#appMounted.then(() => {
      callback(this.#app);
      this.#afterMountComplete.resolve(true);
    });
  }

  /**
   * @description This is intended for use only in unit tests
   * Attempting to get an app instance from this method in live code will result in errors and/or unexpected behavior
   */
  public async getAppInstance() {
    await this.#readyToMount;
    return this.#app;
  }

  /**
   * @description This is intended for use only in unit tests
   */
  public async awaitSpecificStep(step: 'init' | 'before-mount' | 'mount' | 'after-mount'): Promise<boolean> {
    switch (step) {
      case 'init':
        return this.#appReady.promise;
      case 'before-mount':
        // eslint-disable-next-line no-case-declarations
        const all = await this.#readyToMount;
        return all.every(result => result === true);
      case 'mount':
        return this.#appMounted.promise;
      case 'after-mount':
        return this.#afterMountComplete.promise;
      default:
        return this.#afterMountComplete.promise;
    }
  }

  #internalBeforeMount() {
    const pinia = createPinia();

    this.#app.use(PrimeVue, {
      locale: { firstDayOfWeek: 1 },
      theme: {
        preset: Apparatix,
        options: {
          cssLayer: {
            name: 'primevue',
            order: 'tailwind-base, tailwind-utilities, primevue',
          },
          darkModeSelector: '.dark-mode-class-does-not-exist',
        },
      },
    })
      .use(ConfirmationService)
      .use(DialogService)
      .use(ToastService)
      .use(ZiggyVue)
      .use(pinia);

    this.#app.component('Datepicker', Datepicker);
    this.#app.component('Draggable', draggable);
    this.#app.component('SearchInput', SearchInput);

    this.#app.component('Switch', Switch);

    this.#app.component('Dialog', Dialog);
    this.#app.component('InputText', InputText);
    this.#app.component('PvTextarea', Textarea);
    this.#app.component('PvBadge', Badge);
    this.#app.component('PvDivider', Divider);
    this.#app.component('PvDropdown', Dropdown);
    this.#app.component('PvToast', Toast);
    this.#app.component('SwapIcon', SwapIcon);

    autoloadVueComponents(this.#app);

    this.#app.directive('tooltip', Tooltip);

    /** @deprecated import lodash directly where needed */
    window._ = _;
    /** @deprecated import axios directly where needed */
    window.axios = axios;
    window.activeLoading = (action: 'start' | 'stop') => {
      if (action === 'start') {
        window.dispatchEvent(new Event('legacy-active-loading-start'));
      } else {
        window.dispatchEvent(new Event('legacy-active-loading-stop'));
      }
    };
    window._gridGlobalLightNavigationEnabled = false;
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    window.addLeadFromMap = (leadData) => {
      window.dispatchEvent(new CustomEvent('lead-added-from-map', { detail: leadData }));
    };

    /** @deprecated directly import date-fns instead of using moment */
    this.#app.config.globalProperties.$moment = moment;
    /** @deprecated directly import date-fns when needed */
    this.#app.config.globalProperties.$dateFns = dateFns;
    /** @deprecated import lodash directly where needed */
    this.#app.config.globalProperties.$lodash = _;

    this.#internalSetupComplete.resolve(true);
  }

  #buildTitle(initialTitle: string): string {
    if (!initialTitle) return 'Apparatix';
    if (initialTitle.toLowerCase().endsWith('apparatix')) return initialTitle;
    return `${initialTitle} - Apparatix`;
  }
}
