import { ref, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import type {
  ContactDetail, ContactOverview, GetContactAssociationResponse, Note, Options,
} from '@apparatix/types/Contacts';
import { Activity } from '@apparatix/types/Activities';
import type { DataTableFilterMeta } from 'primevue/datatable';
import { FilterMatchMode } from '@primevue/core/api';

const defaultFilters: DataTableFilterMeta = {
  fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  company: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ownerName: { value: null, matchMode: FilterMatchMode.IN },
  address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  emails: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

export const useContactStore = defineStore('contact-store', () => {
  const contacts = ref<ContactOverview[]>([]);
  const contactDetails = ref<ContactDetail>();
  const contactAssociations = ref<GetContactAssociationResponse>();
  const contactActivities = ref<Activity[]>();
  const isLoading = ref(false);

  const options = ref<Options>();
  const contactNote = ref<Note>();
  const showFilters = ref(true);
  const filteredContactLength = ref<number>();
  const selectedContact = ref();

  const invoiceFieldsLocked = ref(true);

  const tableFilters = ref<DataTableFilterMeta>({ ...defaultFilters });
  const previousTableFilters = ref<DataTableFilterMeta>();

  const address = ref({
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    fullAddress: '',
  });

  const resetTableFilters = () => tableFilters.value = { ...defaultFilters };

  const isFilterChanged = computed<boolean>(() => JSON.stringify(tableFilters.value) !== JSON.stringify(defaultFilters));

  const hideTableFilters = () => {
    const activeFilter = JSON.stringify(tableFilters.value);
    const defaultFilter = JSON.stringify(defaultFilters);

    if (activeFilter !== defaultFilter) {
      previousTableFilters.value = { ...tableFilters.value };
    }
    tableFilters.value = undefined;
  };

  const showTableFilters = () => {
    if (previousTableFilters.value) {
      tableFilters.value = { ...previousTableFilters.value };
      previousTableFilters.value = undefined;
    } else {
      resetTableFilters();
    }
  };

  const initializeAddress = () => {
    if (!contactDetails.value) return;

    let addressLine = contactDetails.value.addresses[0];

    if (!addressLine) {
      addressLine = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        postalCode: '',
        fullAddress: '',
      };
    }

    address.value = addressLine;
  };



  const initializeNote = () => {
    if (!contactDetails.value) return;

    let noteLine = contactDetails.value.notes[0];

    if (!noteLine) {
      noteLine = { note: '' };
    }

    contactNote.value = noteLine;
  };

  // Whenever we get an update to contact details, refresh the split out variables in the store.
  watch(
    () => contactDetails.value,
    (newVal) => {
      if (newVal) {
        initializeNote();
        initializeAddress();
      }
    },
  );


  return {
    address,
    contactActivities,
    contactAssociations,
    contactDetails,
    contactNote,
    contacts,
    filteredContactLength,
    hideTableFilters,
    invoiceFieldsLocked,
    isFilterChanged,
    isLoading,
    options,
    resetTableFilters,
    selectedContact,
    showFilters,
    showTableFilters,
    tableFilters,
  };
});
